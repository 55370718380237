%lpTooltipHide {
	opacity: 0;
	pointer-events: none;
	transition: all 0.2s ease-in 0.4s;
}
%lpTooltipShow {
	opacity: 1;
	pointer-events: auto;
	transition: all 0.2s ease-in 0.4s;
}

.lpTooltip {
	@extend %lpTooltipHide;
	position: absolute;
	z-index: 9001;
	&.showUp,
	&:hover {
		@extend %lpTooltipShow;
		&.forceClose {
			@extend %lpTooltipHide;
		}
	}
	.sideTop {
		transform: rotate(225deg);
	}
	.sideBottom {
		transform: rotate(45deg);
	}
	.sideLeft {
		transform: rotate(135deg);
	}
	.sideRight {
		transform: rotate(315deg);
	}
	.lpTooltipArrow {
		position: absolute;
		z-index: 9000;
	}
	.lpTooltip__inner {
		background: #fff;
		position: relative;
	}
}
