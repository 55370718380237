@import 'style-guide.scss';

@mixin headerTextInputsStyle {
	border: 2px solid var(--grey);
	box-sizing: border-box;
	font-family: var(--fontHeading);
	height: 29px;
	padding: 0 8px;
	width: 220px;
}
