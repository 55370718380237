@import './_headerTextInputsStyleMixin';

@mixin searchContainer {
	align-items: center;
	display: flex;
	margin-left: 10%;
	margin-right: 33px;
	position: relative;

	#lpHeader & {
		width: 100%;
	}
}

@mixin searchInput {
	@include headerTextInputsStyle;
	appearance: none; // remove safari rounding style
	border-radius: 0;
	border-right: 0;
	color: #666;
	font-size: 1.4rem;
	height: 40px;
	padding: 0 10px 0 8px;
	width: 287px;

	#lpHeader & {
		width: 100%;
	}
}

@mixin searchArrowBtn {
	background-color: transparent;
	border: 2px solid var(--grey);
	border-left: 0;
	border-radius: 0;
	box-sizing: content-box;
	cursor: pointer;
	height: 36px;
	padding: 0 2px;
	position: relative;
	width: 34px;

	&::before {
		color: #595959;
		font-size: 1.8rem;
		left: 11px;
		position: absolute;
		top: 10px;
	}

	// need to explicitly define outline style to make it not rounded and to match the adjacent Search textbox outline
	&:focus {
		outline: var(--fieldHighlightWidth) solid var(--fieldHighlightColor);
		outline-offset: calc(-1 * var(--fieldHighlightWidth));
	}
}
