@import '../global/style-guide';

#lpModal {
	background: #fff;
	border: 4px solid #000;
	border-radius: 5px;
	bottom: 0;
	box-shadow: 0 0 50px #000;
	display: none;
	left: 0;
	margin: auto;
	overflow: visible !important;
	padding: 18px 15px 15px;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1000;

	// fix for safari bug https://openradar.appspot.com/24235301
	.touch & {
		position: absolute;
	}

	.lpModalClose {
		background: url('/images/global/close.png') no-repeat;
		border: 0;
		cursor: pointer;
		height: 30px;
		position: absolute;
		right: -18px;
		text-indent: -99999px;
		top: -18px;
		width: 90px;
		z-index: 21;
	}

	// Style with no border and different Close button
	&.lpModal--alt1 {
		border: 0;
		border-radius: 0;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		padding: 30px;

		.lpModalClose {
			background: none;
			color: #333;
			font-family: var(--fontHeading);
			font-size: 16px;
			height: auto;
			padding: 6px 9px 3px;
			right: 0;
			text-indent: 0;
			text-transform: uppercase;
			top: 0;
			width: auto;

			.lpIcon-close02 {
				color: #000;
				font-size: 13px;
				font-weight: 500;
				margin-left: 7px;
			}
		}
	}

	&:focus {
		outline: 0;
	}
}

/* !important is needed as jQuery animate() randomly (due to timing?) sticks overflow:hidden inline when you animate this element via lpModalResize() multiple times in succession */
#lpModalContent {
	overflow: auto !important;
	padding: 0 2px;

	.lpModal--noScroll &,
	&.noScroll {
		overflow: hidden !important;
	}
}

#lpModalBackdrop {
	background: rgba(0, 0, 0, 0.6);
	bottom: 0;
	display: none;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1001; // Changing priority of modal overlay prevent from sticky navbar. 
}

// avoid focus flash after focus is programmatically skipped on this element
#iframeModalNextFocus:focus {
	outline: none;
}