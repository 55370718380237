@use 'sass:math';
@import 'style-guide.scss';

// Keyframes
@keyframes onAutoFillStart {
	from {
		/**/
	}

	to {
		/**/
	}
}
@keyframes onAutoFillCancel {
	from {
		/**/
	}

	to {
		/**/
	}
}

.lpForm {
	label {
		line-height: 20px;

		&.accountFormNote {
			font-size: 10px;
			line-height: 12px;
		}

		&.error.valid {
			background: transparent;
			border: 0;
			line-height: normal;
			padding: 0 5px;
		}
	}

	input[type='text'],
	input[type='password'],
	input[type='email'],
	input[type='tel'],
	input[type='number'],
	input[type='url'],
	select {
		border: 1px solid #adadad;
		padding: 4px 2px;
		width: 245px;
	}

	select {
		width: 270px;
	}

	input[type='text'].medium,
	input[type='password'].medium,
	input[type='email'].medium,
	input[type='tel'].medium,
	input[type='number'].medium,
	input[type='url'].medium,
	select.medium {
		width: 125px;
	}

	input[type='text'].small,
	input[type='password'].small,
	input[type='email'].small,
	input[type='tel'].small,
	input[type='number'].small,
	input[type='url'].small,
	select.small {
		width: 100px;
	}

	input[type='text'].exSmall,
	input[type='password'].exSmall,
	input[type='email'].exSmall,
	input[type='tel'].exSmall,
	input[type='number'].exSmall,
	input[type='url'].exSmall,
	select.exSmall {
		width: 75px;
	}

	&.tallFields {
		input[type='text'],
		input[type='password'],
		input[type='email'],
		input[type='tel'],
		input[type='number'],
		input[type='url'],
		select {
			font-size: 14px;
			height: 33px;
			padding-bottom: 0;
			padding-top: 0;
		}

		dd.accountValidation.validationAtBottom .valid {
			top: -26px;
		}
	}

	[placeholder='optional']::placeholder {
		font-style: italic;
	}

	.fieldCheckbox {
		@include clearfix;
		width: 100%;

		#lpModal &,
		.lpModal & {
			padding-left: 2px;
		}

		&.field > label {
			padding-bottom: 1px;
			padding-top: 1px;
		}

		&.focus ~ label::before {
			box-shadow: 0 0 0 $fieldHighlightWidth #fff,
				0 0 0 (2 * $fieldHighlightWidth) $fieldHighlightColorCheckboxRadio;
			transition: none;
		}

		> label {
			padding: 10px 0 10px 30px;
			position: relative;

			&::after,
			&::before {
				box-shadow: none;
				box-sizing: border-box;
				content: '';
				position: absolute;
				transition: all $elementAnimationDuration $elementTimingFunction;
			}
			// Checkmark
			&::after {
				border-bottom: 3px solid #fff;
				border-left: 3px solid #fff;
				height: 8px;
				left: 5px;
				opacity: 0;
				top: 0;
				transform: translateY(70%) rotate(-45deg);
				transform-origin: center center;
				width: 12px;
			}
			// Checkmark Box
			&::before {
				@include retinaBorderWidthHalfPixel;
				background-color: #fff;
				border: 1px solid $checkboxfieldBorderColor;
				border-radius: 4px;
				height: 22px;
				left: 0;
				top: 0;
				width: 22px;
			}
		}

		input[type='checkbox'] {
			-webkit-appearance: none;
			border: 0;
			display: block !important;
			height: 0;
			margin: 0 !important;
			overflow: hidden;
			width: 0 !important;

			&:focus ~ label::before {
				box-shadow: 0 0 0 $fieldHighlightWidth #fff,
					0 0 0 (2 * $fieldHighlightWidth) $fieldHighlightColorCheckboxRadio;
				transition: none;
			}

			&.error ~ label::before {
				box-shadow: 0 0 0 $fieldHighlightWidth #fff,
					0 0 0 (2 * $fieldHighlightWidth) $fieldHighlightColorInvalid;
				transition: none;
			}
		}
		// Checked State
		input[type='checkbox']:checked + label,
		// Support cshtml strangeness providing a hidden element
		input[type='checkbox']:checked + input[type='hidden'] + label {
			// Checkmark
			&::after {
				opacity: 1;
			}
			// Checkmark Box
			&::before {
				background-color: $checkboxBackgroundColorChecked;
				border-color: $checkboxBorderColorChecked;
			}
		}

		input[disabled] {
			+ label {
				color: #aaa;
				cursor: default;

				&::before {
					border-color: #ccc !important;
					cursor: default;
				}

				&::after {
					cursor: default;
				}
			}

			&:checked + label::before {
				background-color: #ccc;
			}
		}
	}

	.fieldRadio {
		width: 100%;

		&.field > label {
			padding-bottom: 0;
			padding-top: 0;
		}

		&.focus ~ label::before {
			box-shadow: 0 0 0 $fieldHighlightWidth #fff,
				0 0 0 (2 * $fieldHighlightWidth) $fieldHighlightColorCheckboxRadio;
			transition: none;
		}

		> label {
			padding: 10px 0 10px 30px;
			position: relative;

			&::after,
			&::before {
				box-shadow: none;
				box-sizing: border-box;
				content: '';
				position: absolute;
				transition: all $elementAnimationDuration $elementTimingFunction;
			}
			// Dot mark
			&::after {
				background-color: $radioBackgroundColorChecked;
				border: 5px solid #fff;
				border-radius: 50%;
				height: 20px;
				left: 0;
				opacity: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 20px;
				z-index: 1;
			}
			// Dot mark outline
			&::before {
				@include retinaBorderWidthHalfPixel;
				border: 1px solid $fieldBorderColor;
				border-radius: 50%;
				height: 20px;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 20px;
				z-index: 2;
			}
		}

		input[type='radio'] {
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;
			border: 0;
			display: block !important;
			height: 0;
			margin: 0 !important;
			overflow: hidden;
			width: 0 !important;

			&:focus ~ label::before {
				box-shadow: 0 0 0 $fieldHighlightWidth #fff,
					0 0 0 (2 * $fieldHighlightWidth) $fieldHighlightColorCheckboxRadio;
				transition: none;
			}

			&.error ~ label::before {
				box-shadow: 0 0 0 $fieldHighlightWidth #fff,
					0 0 0 (2 * $fieldHighlightWidth) $fieldHighlightColorInvalid;
				transition: none;
			}
		}
		// Checked State
		input[type='radio']:checked + label,
		// Support cshtml strangeness providing a hidden element
		input[type='radio']:checked + input[type='hidden'] + label {
			// Checkmark
			&::after {
				opacity: 1;
			}
			// Checkmark Box
			&::before {
				border: 2px solid $radioBorderColorChecked;
			}
		}

		input[disabled] + label,
		input[disabled]:checked + label {
			color: #aaa;

			&::before {
				border-color: #ccc;
			}
		}

		input[disabled]:checked + label {
			&::after {
				background-color: #ccc;
			}
		}
	}

	.clearableInput {
		$clearableInputButtonSize: 20px;
		$clearableInputButtonSizeOffset: 6px;

		&__input {
			padding-right: $clearableInputButtonSize + (2 * $clearableInputButtonSizeOffset) !important;

			&::-ms-clear {
				display: none;
			}
		}

		&__button {
			background: transparent;
			border: 0;
			color: #565656;
			font-size: $clearableInputButtonSize;
			margin-top: math.div(-$clearableInputButtonSize, 2);
			padding: 0;
			pointer-events: auto;
			position: absolute;
			right: $clearableInputButtonSizeOffset;
			top: 50%;
		}
	}

	input[type='password'],
	input[type='tel'],
	input[type='text'],
	input[type='email'],
	select {
		&:not([disabled]) {
			background: #fff;
		}
	}

	.field {
		clear: both;
		padding-bottom: 16px;
		position: relative;
		line-height: 20px;

		&.fieldNoLabel {
			padding-top: 21px;
		}

		&.fieldInline {
			display: inline-block;
			margin-right: 30px;
			width: auto;

			&:last-of-type {
				margin-right: 0;
			}

			label {
				margin-bottom: 0;

				.fieldGroup & {
					margin-right: 0;
				}
			}
		}

		.requiredLabel {
			padding-right: 9px; // to offset space occuppied by *
			position: relative;

			&::after {
				color: #900;
				content: '\002A'; //asterisk character
				display: inline;
				font-size: 18px;
				font-weight: 500;
				margin-left: 1px;
				position: absolute;
			}
		}

		> label,
		> legend {
			display: inline-block;
			font-size: 14px;
			font-weight: normal;
			line-height: 20px;

			&.inlineBlock {
				display: inline-block;
				width: auto;
			}
		}

		> .hintText {
			font-size: 1.1rem;
			position: absolute;
			right: 0;
			transform: translate(0, 2px);
			line-height: 1.5;

			&.right10 {
				right: 10px;
				letter-spacing: -0.5px;
			}
		}

		input + label {
			display: inline-block;
		}

		input,
		select,
		textarea {
			border-radius: 5px;
			box-sizing: border-box;
			display: block;
			width: 100%;

			&.hidden {
				display: none;
			}
		}

		&.fieldInline {
			input {
				display: inline-block;
				vertical-align: top;
			}
		}

		.field-composite {
			position: relative;
		}

		.fieldNote {
			color: #666;
			font-size: 11px;
			line-height: 20px;
		}

		.field-detail {
			border: 0;
			bottom: 0;
			box-sizing: border-box;
			margin: 0;
			padding: 0;
			pointer-events: none;
			position: absolute;
			right: 0;
			text-align: right;
			top: 0;
			width: 100px;
		}

		.field-detail-icon {
			box-sizing: border-box;
			height: 100%;
			padding: 4px;
		}

		.valid + .errorMessage {
			left: 100%;
			position: absolute;
			top: 24px;
		}

		.small {
			font-size: 11px;
		}

		%error-styling {
			background-color: $errorBackgroundColor;
			margin-top: 0;
			outline: 2px solid $fieldHighlightColorInvalid;
			outline-offset: -2px;
			&:focus {
				outline-offset: 2px;
				outline: 2px solid $fieldHighlightColor;
			}
		}

		.fieldComposite.error {
			input[type='password'],
			input[type='tel'],
			input[type='text'],
			input[type='email'],
			select {
				@extend %error-styling;
			}
		}

		input[type='password'],
		input[type='tel'],
		input[type='text'],
		input[type='email'],
		select {
			border: 1px solid #999;
			box-sizing: border-box;
			font-size: $baseFontSize;
			height: $fieldHeight;
			line-height: 34px;
			padding: 0 4px;

			&.error {
				@extend %error-styling;
			}
		}

		select {
			padding: 0 4px;
		}

		input[type='radio'],
		input[type='checkbox'] {
			display: inline-block;
			margin: 3px 8px 3px 0;
			width: auto;
		}

		textarea {
			border: 1px solid #999;
			resize: vertical;

			&:not([disabled]) {
				background: #fff;
			}

			&.error {
				background-color: $errorBackgroundColor;
				margin-top: 0;
				outline: 2px solid $fieldHighlightColorInvalid;
				outline-offset: -2px;
			}
		}
	}

	fieldset.field {
		padding-bottom: 0;
	}

	.fieldGroup {
		clear: both;
		display: flex;
		flex-wrap: wrap;
	}

	.fieldPair {
		clear: both;

		.field {
			box-sizing: border-box;
			clear: none;
			float: left;
			width: 50%;

			&.addressPair {
				padding-bottom: 9px;
			}

			&.field65 {
				width: 65%;
			}

			&.field35 {
				width: 35%;
			}

			&.field75 {
				width: 75%;
			}

			&.field25 {
				width: 25%;
			}

			&:first-child {
				padding-right: 10px;
			}

			&.fieldFull {
				padding-right: 0;
				width: 100%;
			}

			.error.valid {
				right: 50px;
				top: 30px;
			}

			&:last-child .error.valid {
				right: 40px;
			}
		}

		label.error {
			max-width: 139px;
			white-space: inherit;
		}
	}

	.fieldInlineCheckboxes .field {
		margin-left: 20px;

		&:first-child {
			margin-left: 0;
		}
	}

	&.validationMessageOnRight {
		.field .error + .errorMessage {
			position: absolute;
			right: -5px;
			top: 24px;
		}

		.fieldPair .field .error + .errorMessage {
			position: static;
		}
	}

	.fieldComposite {
		position: relative;
	}

	.fieldCompositeDetail {
		border: 0;
		bottom: 0;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		pointer-events: none;
		position: absolute;
		right: 0;
		text-align: right;
		top: 0;
		width: 100px;
	}

	.field-detail-icon {
		box-sizing: border-box;
		height: 100%;
		padding: 4px;
	}

	&.lpForm--compact {
		padding-left: 20px;
		padding-right: 20px;

		.fieldPair label.error {
			max-width: 100% !important;
		}

		.field.fieldInput,
		.field.populated {
			padding-bottom: 25px;

			> label,
			> .hint {
				color: #999;
				cursor: auto;
				pointer-events: none;
				position: absolute;
				transform: translate(7px, 12px);
				transition: transform 0.1s;
				z-index: 1;
			}

			> .hint {
				font-size: 11px;
				right: 0;
				transform: translate(-7px, 2px);
			}

			> .fieldNote + label {
				transform: translate(7px, 32px);
			}

			.textareaLabel {
				// Add white background to prevent content from being seen through the label when textarea is scrolled.
				// Per Marketing, this is an "acceptable" solution.
				background: #fff;
				left: 1px;
				width: calc(100% - 27px); // extend label width but do not cover the textarea scrollbars
			}

			input[type='password'],
			input[type='tel'],
			input[type='text'],
			input[type='email'] {
				font-size: 1.6rem;
				height: 44px;
				line-height: normal;
				padding-left: 6px;
				// expose a hook for js when auto fill is shown, js can capture 'automationstart' events
				&:-webkit-autofill {
					animation-duration: 5000000s;
					animation-name: onAutoFillStart;
				}

				&:not(:-webkit-autofill) {
					animation-name: onAutoFillCancel;
				}

				&.error {
					border: 1px solid #c00 !important;
				}
			}

			textarea {
				font-size: 1.6rem;
				line-height: 1.4em;
				padding: 9px 6px 6px; // Update paddingTopDifference var in JS if top padding is changed
				resize: vertical; // making textarea resizable horizontally causes label layout issues that may not be worth fixing
			}

			select {
				color: #999;
				font-size: 1.6rem;
				height: 44px;

				&:focus {
					color: #000;
				}
			}

			&.populated {
				> label {
					font-size: 12px;
					transform: translate(7px, 1px);
				}

				> .fieldNote + label {
					transform: translate(7px, 21px);
				}

				.textareaLabel {
					left: 0;
					padding-top: 2px;
					top: 0;
				}
				// prevent label to overlap textarea focus outline which has -2px offset
				&.focus .textareaLabel {
					padding-top: 1px;
					top: 1px;
				}

				input[type='password'],
				input[type='tel'],
				input[type='text'],
				input[type='email'],
				textarea {
					border: 1px solid #000;

					&:not(.noLabel) {
						padding-top: 13px;
					}
				}

				textarea:not(.noLabel) {
					padding-top: 18px; // Update paddingTopDifference var in JS if top padding is changed
				}

				select {
					border: 1px solid #000;
					color: #000;
				}
				// IE 11 hack; needed to prevent gap between label and top border of textarea caused by above rule
				@media all and (-ms-high-contrast: none) {
					*::-ms-backdrop,
					&.focus .textareaLabel {
						padding-top: 2px;
						top: 0;
					}
				}
			}

			&.hiddenLabel {
				> label {
					display: none;
				}

				input[type='password'] {
					padding-top: 0;
				}
			}

			label.error,
			.validationContainer .validationError {
				background-color: transparent;
				border: none;
				display: inline-block;
				font-size: 1.2rem;
				padding: 2px 7px 0 0;
				position: absolute;
			}

			input::placeholder {
				font-size: 1.5rem; /* Chrome, Firefox, Opera, Safari 10.1+ */
			}
		}
	}

	.requiredNote {
		color: #900;
		margin: 0;
		text-align: right;
	}

	.requiredNoteInline {
		margin-bottom: -21px;
	}

	.searchInput {
		&:focus {
			outline-offset: -2px;
		}
	}
}

.server-error {
	background: $errorBackgroundColor;
	border: 2px solid $errorBorderColor;
	color: $errorTextColor;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.2em;
	margin: 1em 0;
	padding: 1em;

	// ValidationSummary() creates div > ul container
	ul {
		margin: 0;
	}

	li {
		margin: 0 0 8px 20px;

		&:only-child {
			list-style: none;
			margin: 0;
		}
	}
}

.validationContainer {
	display: none;
	position: relative;
	z-index: 1;
}

.lpForm span.error:not(:empty),
.validationContainer .validationError {
	background: $errorBackgroundColor;
	border: 1px solid $errorBorderColor;
	border-radius: 3px;
	color: $errorTextColor;
	display: block;
	font-size: 1.1rem;
	line-height: 1.3em;
	padding: 5px 7px;
	position: absolute;
	text-transform: none;
	white-space: nowrap;
	z-index: 1;
}

.lpForm span.error.valid {
	background: transparent;
	border: 0;
}
