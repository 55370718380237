@import 'style-guide.scss';
@import 'utilities';

$fadeInOutDuration: 500ms;

// text utils
.uppercase {
	text-transform: uppercase;
}

.fadedIn {
	animation: fadeIn $fadeInOutDuration ease;
}

.fadedOut {
	animation: fadeOut $fadeInOutDuration ease;
}

.isProfessional {
	--baseButtonColor: var(--strongBlue);
	--baseButtonVisitedColor: var(--darkBlue);
	--baseButtonHoverColor: var(--darkBlue);
	--baseButtonSelectedColor: var(--strongBlue);
}

.calloutBtn {
	// defaults
	appearance: none;
	background: var(--baseButtonColor);
	border: 0;
	border-radius: 5px;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-family: var(--fontHeading);
	font-size: 1.4rem;
	line-height: 1.5em;
	outline-color: var(--fieldHighlightColor);
	outline-offset: 3px;
	padding: 0.5em 1.2em;
	position: relative;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;

	&--no-uppercase {
		text-transform: none;
	}

	&--wider {
		padding-left: 2em;
		padding-right: 2em;
	}

	&:visited {
		background: var(--baseButtonVisitedColor);
		border: 0;
		color: #fff;
		text-decoration: none;
	}

	&:hover {
		background: var(--baseButtonHoverColor);
		color: #fff;
	}

	&.selected {
		background: var(--baseButtonSelectedColor);
		color: #fff;
	}

	&:focus {
		background: #000;
		color: #fff;
	}

	// sizing
	&.xxSmall {
		font-size: 0.98rem;
	}

	&.xSmall {
		font-size: 1.12rem;
	}

	&.small {
		font-size: 1.19rem;
	}

	&.large {
		font-size: 1.54rem;
	}

	&.medium {
		font-size: 1.26rem;
	}

	&.xLarge {
		font-size: 1.54rem;
		padding: 0.7em 1.2em;
	}

	&.xxLarge {
		font-size: 1.54rem;
		padding: 1em 1.2em;
	}

	&.wholeWide {
		width: 100%;
	}

	// alt btn style 1
	&.alt1 {
		background: #555;
		color: #fff;

		&:visited {
			background: #555;
			color: #fff;
		}

		&:hover,
		&:focus,
		&.selected {
			background: #595959;
			color: #fff;
		}
	}

	// alt button style 2
	&.alt2 {
		background: #fff;
		box-shadow: inset 0 0 0 1px #555;
		color: #555;

		&:visited {
			background: #fff;
			border: 1px solid #555;
			color: #555;
		}

		&:hover,
		&:focus,
		&.selected {
			color: #c00;
		}

		&.disabled,
		&:disabled,
		&[aria-disabled='true'] {
			box-shadow: inset 0 0 0 1px #ccc;
		}
	}

	// alt button style 3
	&.alt3 {
		background: #000;
		color: #fff;

		&:visited {
			background: #000;
			color: #fff;
		}

		&:hover,
		&:focus,
		&.selected {
			background: #555;
			color: #fff;
		}
	}

	&.alt4 {
		border: 1px solid #000;

		&,
		&:visited,
		&:hover,
		&:focus,
		&.selected {
			background: transparent;
			color: #000;
		}
	}

	// white color, white border, transparent background
	&.alt5 {
		border: 1px solid #fff;

		&,
		&:visited,
		&:hover,
		&:focus,
		&.selected {
			background: transparent;
			color: #fff;
		}
	}

	// gray button with black text
	&.alt5 {
		border: 1px solid #ebe9e3;

		&,
		&:visited,
		&:hover,
		&:focus,
		&.selected {
			background: #ebe9e3;
    		color: #000;
		}
	}

	// white button with black text and same hover color
	&.alt6 {
		border: 1px solid #fff;

		&,
		&:visited,
		&:hover,
		&:focus,
		&.selected {
			background: #fff;
    		color: #000;
		}
	}

	// Loading icon
	&::before {
		animation: spin 1s linear infinite;
		animation-play-state: paused;
		color: #000;
		content: '\e960';
		display: block;
		font-family: 'LPIcons' !important;
		font-size: 2.1rem;
		height: 1em;
		left: 50%;
		line-height: 1;
		margin-left: -0.5em;
		margin-top: -0.5em;
		opacity: 0;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		text-align: center;
		top: 50%;
		transform-origin: center center;
		transition: color 250ms ease-in-out, opacity 250ms ease-in-out;
		width: 1em;
		z-index: 1;
	}

	&.loading {
		&::before {
			animation-play-state: running;
			opacity: 1;
		}
	}

	// in some scenarios showing loading spinner is not desired
	&.loading.noSpinner {
		&::before {
			display: none;
		}
	}

	&.alt2::before {
		color: #aaa;
	}

	&.alt3::before {
		color: #aaa;
	}

	&:active::before {
		color: #aaa;
	}

	&.disabled::before,
	&[aria-disabled='true'] {
		color: #777;
	}

	&.xxSmall::before {
		font-size: 14px;
	}

	&.xSmall::before {
		font-size: 1.8rem;
	}

	&.small::before {
		font-size: 1.8rem;
	}

	&.medium::before {
		font-size: 1.9rem;
	}

	&.large::before {
		font-size: 2.3rem;
	}

	&.xLarge::before {
		font-size: 2.8rem;
	}

	&.xxLarge::before {
		font-size: 3.4rem;
	}

	// Text wrapper to display in front of loading icon
	> span {
		position: relative;
		z-index: 2;
	}

	// disabled states
	&.disabled,
	&.disabled:hover,
	&.disabled:hover:visited,
	&.disabled:visited,
	&:disabled,
	&:disabled:hover,
	&[aria-disabled='true'] {
		background: #ccc !important;
		border-color: #ccc !important;
		color: #555 !important;
		cursor: default !important;
		pointer-events: none !important;
	}

	// button set
	.buttonSet & {
		float: left;
		margin-left: 1px;

		&:first-child {
			border-radius: 2px 0 0 2px;
			margin-left: 0;
		}

		&:last-child {
			border-radius: 0 2px 2px 0;
		}
	}

	&.hidden {
		display: none;
	}
}

.hidden,
[class^='lpIcon-'].hidden {
	display: none;
}

.invisible {
	visibility: hidden;
}

.noPointerEvents {
	pointer-events: none;
}

.clear {
	clear: both;
	display: block;
	font-size: 0;
	height: 0;
	line-height: 0;
	visibility: hidden;
}

.noselect {
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;
}

// modern clearfix per Aug 2012 http://css-tricks.com/snippets/css/clear-fix/
.group::after {
	clear: both;
	content: '';
	display: table;
}

.fr {
	float: right;
}

.fl {
	float: left;
}

.tac {
	margin-top: 15px;
	text-align: center;
}

.tal {
	text-align: left;
}

.tar {
	text-align: right;
}

.pseudoLink {
	text-decoration: underline;
}

// unstyled lists
.unstyledList {
	list-style: none;
	margin: 0;
	padding: 0;
}

// Pipe separated list
.pipeList {
	@include clearfix;
	list-style: none;
	margin: 0;
	padding: 0;

	> li {
		display: inline-block;

		&::after {
			content: ' |';
			padding: 0 4px;
		}

		&:last-child::after,
		&.last-visible-child::after,
		&.noPipe::after,
		&.hidden {
			display: none;
		}
	}

	&.frontPipe > li:first-child::before {
		content: '|';
		padding: 0 8px;
	}
}

.pipeListFlex {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;

	> li {
		&::after {
			content: ' |';
			padding: 0 4px;
		}

		&:last-child::after,
		&.last-visible-child::after,
		&.noPipe::after,
		&.hidden {
			display: none;
		}
	}

	&.frontPipe > li:first-child::before {
		content: '|';
		padding: 0 8px;
	}
}

.disclaimer {
	color: #555;
	font-size: 12px;
	line-height: 1.3;

	&.red {
		color: $lpRed;
	}
}

// Breadcrumb style (ex: /lighting-catalog, /mobile pages)
// For grep: .sortBreadCrumb
.breadcrumb {
	background: none repeat scroll 0 0 #fff;
	color: #666;
	display: table-cell;
	font-size: 1.3rem;
	font-weight: 500;
	height: 50px;
	text-transform: capitalize;
	vertical-align: middle;

	a,
	a:visited,
	a:active {
		color: #666;
		text-decoration: none;
	}

	> ul li {
		display: inline-block;
		margin: 0;
		padding: 0 1px;
	}

	.rootLink {
		vertical-align: middle;
	}
}

// Helper Classes
sup,
.currencySymbol,
.currencyCents {
	font-size: 0.7em;
	position: relative;
	top: -0.3em;
	vertical-align: baseline;
}

// color
.lpRed {
	color: $lpRed;
}

.caret {
	@include caret();
	cursor: pointer;
	display: inline-block;

	&.down {
		margin-bottom: 2px;
		transform: rotate(45deg);
	}

	&.up {
		margin-bottom: -3px;
		transform: rotate(-135deg);
	}

	&.right {
		margin-bottom: 1px;
		transform: rotate(-45deg);
	}

	&.left {
		margin-bottom: 1px;
		transform: rotate(135deg);
	}

	&.small {
		@include caret($size: 4px);
	}

	&.large {
		@include caret($size: 12px, $thickness: 7px);
	}

	&.disabled {
		cursor: default;
		opacity: 0.2;
	}
}

.revealPasswordWrapper {
	clear: left;
	display: inline-block;
	position: relative;

	&--wide {
		display: block;
	}

	.passwordReveal {
		padding-right: 50px !important;
	}

	.toggleRevealPassword {
		cursor: pointer;
		font-size: 1.1rem;
		line-height: $fieldHeight;
		padding: 0 10px;
		position: absolute;
		right: 0;
		top: 0;

		&:focus {
			outline: 2px solid #06b;
			outline-offset: -2px;

			&::before {
				display: none;
			}
		}

		&::before {
			border-left: 1px solid #adadad;
			content: '';
			height: 16px;
			left: -1px;
			margin-top: -8px;
			position: absolute;
			top: 50%;
		}
	}

	button.toggleRevealPassword {
		background: none;
		border: none;
	}
}

.revealPassword {
	.revealPasswordWrapper & {
		padding-right: 55px !important; // Override global style
	}

	// For IE
	&::-ms-clear,
	&::-ms-reveal {
		display: none;
	}
}

// Print helper classes (Similar to Bootstrap)
// Media queries are placed on the inside to be mixin-friendly.
.visiblePrintBlock {
	display: none !important;

	@media print {
		display: block !important;
	}
}

.visiblePrintInline {
	display: none !important;

	@media print {
		display: inline !important;
	}
}

.visiblePrintInlineBlock {
	display: none !important;

	@media print {
		display: inline-block !important;
	}
}

@media print {
	.hiddenPrint {
		display: none !important;
	}
}

// For accessibility items
.visuallyHidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

// chat wrapper
.chatWrapper {
	background-color: #fff;
	border: 1px solid #555;
	border-radius: 5px;
	display: inline-block;
	line-height: 22px;
	margin-left: 5px;
	padding: 2px 0;

	.chatButtonContainer {
		display: inline-block;
		height: 21px;
		width: 88px;

		button {
			background: transparent;
			border: none;
			display: block;
			height: 26px;
			padding-left: 30px;
			position: relative;
			text-transform: uppercase;
			top: -2px;
			width: 100%;
		}
	}

	.designChatContainer {
		width: 128px !important;
	}
}

// chat icon
.chatWrapperWithIcon {
	cursor: pointer;
	position: relative;
	z-index: 1;

	button {

		&::before,
		&::after {
			display: block;
			line-height: 1;
			pointer-events: none;
			position: absolute;
			z-index: -1;
		}
		&::after {
			content: '\e910';
			font-family: 'LPIcons' !important;
			font-size: 20px;
			left: 10px;
			top: 4px;
		}
	}
}

// Loading Indicator with transparent background
.loadingOverlay2 {
	background: rgba(255, 255, 255, 0.75);
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;

	.loader {
		height: 100px;
		left: calc((50%) - (50px));
		position: absolute;
		text-align: center;
		text-transform: uppercase;
		top: calc((50%) - (50px));
		width: 100px;
	}
}

.loadingIndicatorWrapper,
.loadingOverlay2 {
	.spinner {
		animation: spin 1.25s infinite linear;
		font-size: 2.5em;
	}
}

body.loading {
	&::before {
		animation: spin 1.25s infinite linear;
		content: '\e960';
		font-family: 'lpIcons';
		font-size: 2.5em;
		position: fixed;
		text-align: center;
		top: 48%;
		width: 100%;
		z-index: 99999;
	}

	&::after {
		background: rgba(255, 255, 255, 0.5);
		content: '';
		height: 100%;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 99998;
	}
}

.anchorLink {
	background: none;
	border: 0;
	color: var(--baseTextColor);
	cursor: pointer;
	font-family: inherit;
	font-size: inherit;
	outline-color: $fieldHighlightColor;
	outline-offset: 1px;
	padding: 0;
	text-decoration: underline;

	&[aria-disabled='true'] {
		color: #ccc;
		cursor: default;
		pointer-events: none;
	}

	&:hover {
		color: $lpRed;
	}
}

.circlePlusIcon {
	@include circlePlusIcon;

	&--small {
		@include circlePlusIcon($circleDiameter: 19px, $plusSize: 12px, $borderSize: 1.5px, $plusThickness: 2px);
	}
}

// add skeleton class to elements that should show a grey animated block while loading. Will apply also to all images
// using the unveil class. Element must have a width and height set - set that for each area separately.
.skeleton,
.unveil:not(.transparent) {
	background: linear-gradient(
		90deg,
		rgba(127, 127, 127, 0.15) 0%,
		rgba(127, 127, 127, 0.25) 45%,
		rgba(127, 127, 127, 0.24) 50%,
		rgba(127, 127, 127, 0.15) 100%
	);
	background-attachment: fixed;
	background-repeat: repeat;
	background-size: 200% 200%;
	display: block;

	&:not(.notInView):not(.unveil--done) {
		animation: 1s ease 0s infinite skeletonAnimation;
	}
}

.skeleton {
	&--smalltext {
		height: 1em;
		margin: calc(1em / 2) 0;
	}

	&--mediumtext {
		height: 1.5em;
		margin: calc(1.9em / 2) 0;
	}

	&--width100 {
		width: 100%;
	}

	&--width80 {
		width: 80%;
	}

	&--width30 {
		width: 30%;
	}

	&--button {
		border: 1px solid #555;
		height: 32px;
	}

	&--circle {
		border-radius: 50%;
	}

	&--pill {
		display: inline-block;
		height: 4rem;
		vertical-align: middle;
		width: 12rem;
	}
}

//only apply the border radius while images are loading.  Other styles are safe to leave on unveil class.  If using skeleton class, handle removing the class after loading via js.
.skeleton:not(.notInView),
.unveil[src*='trans.gif']:not(.transparent):not(.notInView) {
	border-radius: 3px;
}

.shareLink__block #shareLinkCopyBtn {
	box-sizing: border-box;
	padding: 0 1.2em;
}

.infoIcon {
	background: #f1f1f1;
	border-radius: 110px;
	border: 1px solid #000;
	color: #000;
	cursor: pointer;
	font-family: var(--fontHeading);
	font-size: 1.2rem;
	padding: 0px 6px;
	position: relative;
	text-align: center;
	text-decoration: none;
	top: -2px;
}
