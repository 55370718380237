html {
	background-color: #fff;
	background-color: var(--baseBackgroundColor);
	color: #333;
	color: var(--baseTextColor);
	// https://snook.ca/archives/html_and_css/font-size-with-rem
	font-size: 62.5%;
	font-family: var(--fontBody);
	height: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-text-size-adjust: none;
}

body {
	background-color: #fff;
	background-color: var(--baseBackgroundColor);
	color: #333;
	color: var(--baseTextColor);
	margin: 0;
	min-height: 100%;
}

body,
td,
input,
select,
textarea {
	font-size: var(--baseFontSize);
	line-height: var(--baseLineHeight);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-size: 1.9rem;
	font-weight: 500;
	letter-spacing: -.04rem;
	line-height: 2rem;
}

@include media(desktop) {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	.subHeading {
		color: #000;
		font-family: var(--fontHeading);
		line-height: 1em;
		margin: 0 0 0.5em;

		&.unweighted,
		.unweighted {
			font-weight: normal;
		}

		&.closer {
			margin: 0 0 0.3em;
		}
	}

	h1,
	.h1 {
		font-size: 2em;
		font-weight: 500;
		letter-spacing: -0.04em;
	}

	h2,
	.h2 {
		font-size: 1.8em;
		font-weight: 400;
		letter-spacing: -0.04em;
	}

	h3,
	.h3 {
		font-size: 1.3em;
		font-weight: 400;
		letter-spacing: -0.04em;
	}

	h4,
	.h4 {
		font-size: 1.4em;
		font-weight: 400;
		letter-spacing: -0.03em;
	}

	h5,
	.h5 {
		font-size: 1.2em;
		font-weight: 400;
		letter-spacing: -0.02em;
	}

	h6,
	.h6 {
		font-size: 1em;
		font-weight: 400;
		letter-spacing: -0.01em;
	}

	.subHeading {
		font-size: 1.2em;
		font-weight: 400;
		line-height: 1.2em;
		text-transform: uppercase;
	}
}

a {
	color: var(--baseTextColor);
	outline-color: var(--fieldHighlightColor);
}

// Disable iOS Context menu for JavaScript driven links
a[href='#'] {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
}

@include media(desktop) {

	.pseudoLink,
	a,
	a:active,
	a:visited,
	a:hover {
		color: #333; // IE11 Support
		color: var(--baseTextColor);
		cursor: pointer;
	}

	.pseudoLink {
		background: none;
		border: 0;
		font-family: inherit;
		padding: 0;
		text-decoration: underline;
	}

	a:hover,
	.pseudoLink:hover {
		color: var(--red);
	}

	// remove hover state for touch devices and use :active color for visual cue
	@media (hover: none) {

		a:hover,
		.pseudoLink:hover {
			color: var(--baseTextColor);
		}

		a:active {
			color: var(--red);
		}
	}
}

img {
	height: auto;
	max-width: 100%;
}

::-webkit-input-placeholder {
	color: var(--fieldPlaceholderTextColor);
}

::-moz-placeholder {
	color: var(--fieldPlaceholderTextColor);
}

:-ms-input-placeholder {
	color: var(--fieldPlaceholderTextColor);
}

// Forms
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

input,
select {
	color: var(--baseTextColor);
	vertical-align: middle;
}

textarea {
	overflow: auto;
}

//input, textarea, button {font:99% sans-serif;}
label,
input[type='button'],
input[type='submit'],
input[type='image'],
button {
	cursor: pointer;

	&[disabled] {
		cursor: default;
	}
}

input[type='radio'] {
	height: 13px;
	margin: 3px 3px 3px 4px;
	outline-color: var(--fieldHighlightColor);
	padding: 0;
	vertical-align: text-bottom;
	width: 13px;
}

input[type='checkbox'] {
	height: 13px;
	margin: 3px 3px 3px 4px;
	outline-color: var(--fieldHighlightColor);
	padding: 0;
	vertical-align: bottom;
	width: 13px;
}

input[type='search'] {
	box-sizing: content-box;
}

input:not([type='checkbox'], [type='radio']),
select,
textarea {
	border-radius: 5px;

	&:focus {
		outline: var(--fieldHighlightWidth) solid var(--fieldHighlightColor);
		outline-offset: 2px;
	}
}

*[tabindex],
button {
	outline-color: var(--fieldHighlightColor);
	border-radius: 2px;
}

[role*='dialog'] {

	*[tabindex]:focus,
	a:focus,
	button:focus {
		outline: 2px solid var(--fieldHighlightColor);
	}
}

button {
	color: #000;
}

hr {
	border: 0;
	border-top: 1px solid var(--baseBorderColor);
	margin: 0;
}