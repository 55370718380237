html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption {
	border: 0;
	font-size: 100%;
	font: inherit;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

tr,
th,
td {
	vertical-align: top;
}

body,
button,
td,
input,
select,
textarea {
	font-family: inherit;
	font-size: 1.4rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 1.6rem;
	font-weight: 500;
}

ul,
ol {
	margin-left: 2em;
}

ol {
	list-style-type: decimal;
}

ul {
	margin-bottom: 1em;
	margin-top: 1em;
}

em {
	font-style: italic;
}

strong, b, th {
	font-weight: 500;
}

p {
	margin: 0 0 1em;
}

img {
	border: 0;
}

iframe {
	display: block;
}
