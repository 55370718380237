@import '_style-guide.scss';

body.initialLoading {
	.lpFooter {
		visibility: hidden;
	}
}

.lpFooter {
	background: #fff;
	display: flex;
	flex-grow: 1;
	margin-top: 40px;
	min-width: 960px;

	&.lpProFooter {
		display: block;
	}

	#lblStandardFooter {
		margin-top: auto;
		width: 100%;
	}
}

.lpProFooter {
	background-color: #f1f1f1;

	#lblProFooter .professional #ftrLegal {
		ul {
			align-items: center;
			display: flex;
			justify-content: space-between;
			list-style: none;
			margin: 10px auto 20px;
		}

		#ftrSocialIcons {
			.h5 {
				font-size: 2.4rem;
				font-weight: 500;
			}

			a {
				text-decoration: none;
			}

			a span[class^='lpIcon'] {
				font-size: 2.4rem;
				margin: 0 18px;
			}
		}
	}
}

#footer {
	background-color: #f1f1f1;
	max-width: 100%;
	min-width: 960px;
	position: relative;
	z-index: 2;

	&.professional {
		.ftrLinkList-col.proRep {
			flex: none;
			position: relative;
			width: 40%;

			&::before {
				border-left: 1px solid #ccc;
				content: '';
				height: 100%;
				left: -17%;
				position: absolute;
				top: 0;
			}

			.callProMessageText {
				display: block;
			}

			.csrContact {
				.column.needHelp {
					display: inline-flex;

					img {
						height: 122px;
						margin-right: 10px;
					}
				}
			}
		}

		#ftrLegal #ftrSocialIcons li a {
			&.ftrSocialIcons {
				outline-offset: -6px;
				padding: 18px 0 8px 0;
			}

			.lpIcon-tiktok {
				img {
					height: 21px;
				}

				&:hover {
					img {
						filter: brightness(0.5);
					}
				}

				&.tiktok-pros {
					img {
						opacity: 0.7;
						&:hover {
							filter: invert(9%) sepia(73%) saturate(6904%) hue-rotate(4deg) brightness(106%)
								contrast(116%);
							opacity: 1;
						}
					}
				}
			}

			&:focus {
				.lpIcon-tiktok.tiktok-pros {
					img {
						@media screen and (max-width: 1200px) {
							filter: invert(0%) sepia(95%) saturate(0%) hue-rotate(140deg) brightness(104%)
								contrast(105%);
						}
					}
				}
			}
		}
	}
}

// START: LP Footer Links
#ftrLinksSection {
	@include responsiveWidthLayout;
	margin: 0 auto;
	padding: 15px 0;

	&.pro {
		border-top: 0;
	}

	.proLinksLayout,
	.standardLinksLayout,
	.hospitalityLinksLayout {
		display: flex;
		justify-content: space-between;
		padding: 15px 10px;
	}

	.proLinksLayout {
		padding: 10px 30px;
	}

	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
		.resources {
			flex-grow: 0.2;
		}
	}
}

.ftrGlobal {
	.proLinksLayout,
	.standardLinksLayout,
	.hospitalityLinksLayout {
		display: flex;
		justify-content: space-between;
		padding: 15px 10px;
	}

	.proLinksLayout {
		padding: 10px 30px;
	}

	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
		.resources {
			flex-grow: 0.2;
		}
	}
}

.ftrLinkList-col--header,
.ftrLinkList-col--item {
	margin-bottom: 5px;
}

.ftrLinkList-col--header {
	color: #000;
	font-family: var(--fontHeading);
	font-size: 1.3rem;
	font-weight: 500;
	text-transform: capitalize;

	@media (min-width: 1040px) {
		font-size: 1.6rem;
	}
}

.ftrLinkList-col--item {
	text-align: left;

	a,
	button,
	span {
		color: #000;
		font-size: 1.1rem;
		line-height: 24px;
		text-decoration: none;

		@media (min-width: 1040px) {
			font-size: 1.2rem;
		}

		.standardLinksLayout & {
			font-size: 1.15rem;

			@media (min-width: 1040px) {
				font-size: 1.3rem;
			}
		}

		.proLinksLayout & {
			font-size: 1.2rem;

			@media (min-width: 1040px) {
				font-size: 1.3rem;
			}
		}

		.hospitalityLinksLayout & {
			font-size: 1.3rem;

			@media (min-width: 1040px) {
				font-size: 1.4rem;
			}
		}
	}

	a:hover {
		text-decoration: underline;
	}

	button#footer_rate_us:hover {
		color: #000;
		text-decoration: underline;
	}
}

// END: LP Footer Links

// START: Social Icons
.ftrSocialMedia {
	margin-bottom: 20px;

	#ftrSocialContainer {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		list-style: none;
		margin: 0 auto;
		width: 420px;

		.h5,
		.ftrSocialIcons {
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}

			span {
				color: #000;
				font-size: 2rem;

				@media (min-width: 1280px) {
					font-size: 2.2rem;
				}
			}
		}

		.h5 {
			font-size: 1.6rem;
			font-weight: 500;
			margin: 0 0 5px;
			width: 140px;
		}

		a.ftrSocialIcons {
			padding: 10px 5px 2px 6px;

			&:last-child {
				@media screen and (max-width: 1024px) {
					margin-right: 10px;
				}
			}
		}
	}

	span.lpIcon-tiktok img {
		height: 19px;
	}
}
// END: Social Icons

// START: LP Footer Legal
#ftrLegal {
	background-color: #f1f1f1;
	border-top: solid 1px #ccc;
	margin: 0 auto;
	max-width: unset;
	min-width: unset;
	padding: 20px 0 30px;
	text-align: center;
	width: 100%;

	.disclaimer {
		color: #000;
		font-size: 1.2rem;
		margin: 10px auto 0;
		max-width: 120em;
		padding-left: 30px;
		padding-right: 30px;
		text-align: center;
		& + br + .disclaimer.ftrWidCont {
			margin-top: 0;
		}
	}
	.footnotes {
		ul {
			li {
				display: block;
				a {
					text-decoration: none;
				}
			}
			li,
			li:focus {
				outline: none;
			}
		}
	}
}

#ftrLegal-links {
	align-items: center;
	color: #000;
	display: flex;
	flex-wrap: wrap;
	font-size: 1.2rem;
	justify-content: center;
	margin: 0 auto;
	text-align: center;
	width: 100%;

	.ftrShippingText::before {
		@media (min-width: 1025px) {
			content: '\a';
			white-space: pre;
		}
	}

	span.ftrLinksSection::after {
		display: none !important;
	}

	@media (max-width: 1025px) {
		.ftrLegalLinksContainer {
			margin-top: 5px;
		}

		span.ftrBottom::after,
		span.ftrRights::after {
			display: none !important;
		}

		.disclaimer {
			padding-left: 0;
			padding-right: 0;

			.disclaimerInner {
				margin-top: 5px;
			}
		}
	}

	a:not(:last-child) {
		&::after {
			content: '|';
			position: absolute;
			right: -9px;
			top: -1px;
		}

		&:hover::after {
			color: var(--baseTextColor);
		}
	}

	a {
		margin-right: 14px;
		outline: none;
		position: relative;
		text-decoration: none;

		&.fullpolicyLink{
			text-decoration: underline;
		}

		&:last-child {
			margin: 0;
		}

		&:focus {
			border-radius: 2px;
			box-shadow: 0 0 0px 2px var(--fieldHighlightColor);
		}
	}
}

// END: LP Footer Legal

// START: Misc / Legacy

//Some footer tracking elements occupy a space and cause scrollbars in popups
#bdPopup {
	#footerTracking {
		iframe[name='google_conversion_frame'],
		img {
			left: -99999px;
			position: absolute;
			top: -99999px;
		}
	}
}

#ftrAssetManagement {
	background-color: #f1f1f1;
	margin: auto;
	padding: 15px 0;
	text-align: center;
	width: 960px;
}

// START: Recently Viewed inside footer
#lblStandardFooter,
#lblProFooter {
	.recentlyViewedWrapper {
		@include responsiveWidthLayout;
		padding-top: 10px;

		.sortMoreYouMayLikeTitle,
		.sortRecentlyViewedTitle {
			float: left;
			line-height: 28px;
			margin: 0 5px 0 0;
			font: 400 2rem var(--fontHeading);
		}

		&:not(.loaded) .viewAllRecentlyViewedBtn {
			display: none;
		}
	}
}

// END: Recently Viewed inside footer

.hashTagLampsplus {
	&::before {
		content: '#';
	}
}

#noScriptContainer,
#noCookiesContainer {
	background-color: #fff;
	border-bottom: 4px solid #000;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 9999;
}

#noScriptContainer p,
#noCookiesContainer p {
	margin: auto;
	padding: 15px 0;
	width: 792px;
}

#noScriptContainerBkg,
#noCookiesContainerBkg {
	background: #000;
	height: 100%;
	left: 0;
	opacity: 0.4;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 9998;
	zoom: 1;
}

#noCookiesContainer,
#noCookiesContainerBkg {
	display: none;
}

#freeShippingOverlay {
	padding: 40px 0 0;
	text-align: center;

	a {
		color: #666;
	}
}

#stopAssetSharingTooltip {
	background-color: #fff;
	display: none;
	font-size: 14px;
	font-weight: normal;
	padding: 20px 30px;
	width: 313px;

	a.cancel {
		margin-right: 30px;
	}
}

.chatButtonContainer {
	text-indent: -99999px;
}

.ratingForm {
	.zipEmail {
		.field label {
			float: unset;
		}
	}
}

.hybridContent {
	height: 0;
	overflow: hidden;
	visibility: hidden;

	&-read-more {
		color: black;
	}
}
