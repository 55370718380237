// Clearfix Mixin
@mixin clearfix {
	&::before,
	&::after {
		clear: both;
		content: '';
		display: table;
	}
}
@mixin solidDownArrow($color, $baseLength: 6px, $sideLength: 6.5px) {
	border-color: $color transparent transparent;
	border-style: solid;
	border-width: $baseLength $sideLength 0 $sideLength;
	height: 0;
	width: 0;
}
@mixin solidUpArrow($color, $baseLength: 6px, $sideLength: 6.5px) {
	border-color: transparent transparent $color transparent;
	border-style: solid;
	border-width: 0 $sideLength $baseLength $sideLength;
	height: 0;
	width: 0;
}
@mixin solidRightArrow($color, $baseLength: 6px, $sideLength: 6.5px) {
	border-color: transparent transparent transparent $color;
	border-style: solid;
	border-width: $sideLength 0 $sideLength $baseLength;
	height: 0;
	width: 0;
}
@mixin solidLeftArrow($color, $baseLength: 6px, $sideLength: 6.5px) {
	border-color: transparent $color transparent transparent;
	border-style: solid;
	border-width: $sideLength $baseLength $sideLength 0;
	height: 0;
	width: 0;
}
@mixin caret($size: 7px, $thickness: 2px, $color: #000) {
	border-bottom: $thickness solid $color;
	border-right: $thickness solid $color;
	height: $size;
	width: $size;
}
@mixin mediaPrint() {
	tr,
	img {
		page-break-inside: avoid;
	}

	.kioskPrintColumn {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	#lpHeader-hdrPortal,
	#lpHeader-hdrWrapper--right,
	#lpHeader-navWrapper,
	#footer {
		display: none;
	}
}

%rangeFilter {
	border-top: 1px solid #ccc;
	clear: both;
	margin: 10px 0 8px 15px;
	padding-top: 15px;

	span {
		float: left;
		line-height: 35px;
		margin-right: 4px;

		&:nth-of-type(2) {
			margin-left: 4px;
		}
	}

	input {
		border: 1px solid #999;
		box-sizing: border-box;
		float: left;
		font-size: 14px;
		height: 35px;
		padding-left: 5px;
		width: 47px;
	}

	button {
		background: #999;
		border: 1px solid #999;
		color: #fff;
		float: left;
		height: 35px;
		margin-left: 6px;
		padding: 0;
		position: relative;
		text-indent: -99999px;
		width: 29px;

		&::after {
			@include caret($thickness: 2px, $color: #fff);
			content: '';
			display: block;
			left: 7px;
			position: absolute;
			top: 12px;
			transform: rotate(-45deg);
		}
	}
}
@mixin arrow($size, $color, $direction) {
	height: 4 * $size;
	width: 4 * $size;

	&::after {
		border-right: $size solid $color;
		border-top: $size solid $color;
		box-sizing: border-box;
		content: '';
		display: block;
		height: 4 * $size;
		width: 4 * $size;

		else {
			transform: rotate($direction);
		}
		@if $direction == 'right' {
			transform: rotate(45deg);
		} @else if $direction == 'left' {
			transform: rotate(-135deg);
		}
	}
}

@mixin wideCaret($size, $color, $direction) {
	&::before {
		border: 1px solid $color;
		content: '';
		display: block;
		width: $size;

		else {
			transform: rotate($direction);
		}
		@if $direction == 'right' {
			transform: rotate(62deg);
		} @else if $direction == 'left' {
			transform: rotate(118deg);
		}
	}

	&::after {
		border: 1px solid $color;
		content: '';
		display: block;
		position: absolute;
		top: $size - 2px;
		width: $size;

		else {
			transform: rotate($direction);
		}
		@if $direction == 'right' {
			transform: rotate(-62deg);
		} @else if $direction == 'left' {
			transform: rotate(-118deg);
		}
	}
}

@mixin circlePlusIcon(
	$circleDiameter: 40px,
	$plusSize: 20px,
	$plusThickness: 3px,
	$borderSize: 3px,
	$borderColor: #565656,
	$backgroundColor: transparent
) {
	background: $backgroundColor;
	border: $borderSize solid $borderColor;
	border-radius: 100%;
	display: inline-block;
	height: $circleDiameter;
	position: relative;
	width: $circleDiameter;

	&::before {
		background: $borderColor;
		content: '';
		height: $plusThickness;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: $plusSize;
	}

	&::after {
		background: $borderColor;
		content: '';
		height: $plusSize;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: $plusThickness;
	}
}
