@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

// wave animation with gradient
@keyframes skeletonAnimation {
	from {
		background-position: 0 0;
	}

	to {
		background-position: -200% 0;
	}
}
