@import "utilities";

@media print {
	@include mediaPrint;
	* {
		filter: none !important;
		text-shadow: none !important;
	}
	#pdProdToolbar,
	#customizeColors .patternColors.small {
		display: none;
	}
}
