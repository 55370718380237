@import 'style-guide.scss';
@import './../global/utilities';
@import './_headerTextInputsStyleMixin';
@import './searchMixins';

// Colors
$darker-gray: #999;
$dark-gray: #595959;

// Elements in header nav
$headerElementHeight: 20px;

// Category dropdowns
$navAnchorHeight: 40px;
$navWrapperHeight: $navAnchorHeight + $categoryNavHoverBottomBorderWidth;

// Header Nav dropdowns
$headerNavDdAnchorHeight: 20px;
$headerNavDdWrapperHeight: $headerNavDdAnchorHeight + $categoryNavHoverBottomBorderWidth;

$heartIconSize: 2.2rem;

// Mixins
@mixin headerheaderDropDownArrow {
	border: solid black;
	border-width: 0 1px 1px 0;
	display: inline-block;
	margin: 2px 5px;
	padding: 3px;
	transform: rotate(45deg);
}

:root {
	--hdrPortal-portalLink-margin: 20px;
	--header-nav-hover-line-color: #ccc;
	--lpHeader-hdrWrapper-margin: 40px;
	--header-nav-highlight-color: var(--red);

	@media (min-width: $lpHeaderMinBreakpoint) {
		--hdrPortal-portalLink-margin: 50px;
		--lpHeader-hdrWrapper-margin: 70px;
	}
}

#applicationNode {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

#lpContainer {
	margin: 0 auto;
	width: 960px;

	.responsive & {
		@include responsiveWidthLayout;
	}
}

// Misc
#skipLinks {
	a {
		height: 1px;
		left: -99999px;
		padding: 10px 24px;
		position: absolute;
		width: 1px;
		z-index: 1;

		&:focus,
		&:active {
			background-color: #000;
			border: none;
			color: #fff;
			display: inline-block;
			height: auto;
			left: auto;
			outline: none;
			top: 0;
			width: auto;
		}
	}
}

.lpTooltip .close {
	background: transparent url('/img/global/tooltip-close.png') no-repeat;
	display: inline-block;
	height: 10px;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 10px;

	&::after {
		content: '';
	}
}

#lpHeader {
	@include responsiveWidthLayout($lpContainerMinWidth, $lpHeaderMaxWidth);
	font: 400 1.4rem var(--fontHeading);
	outline: none;

	input[type='text'] {
		@include headerTextInputsStyle;
	}

	@media (orientation: portrait) {
		width: calc(100% - 20px);
	}
}

// START: LP Header Content section
#lpHeader-wrapper {
	$maxWrapperWidth: $lpContainerMinWidth + ($responsiveSideGutter * 2) + $scrollbarWidth;
	margin: auto;
	padding-left: 0;
	padding-right: 0;
}

#lpHeader-content {
	background-color: #fff;
	border-bottom: 1px solid #ccc;
	margin: 0 auto;
	max-width: $lpHeaderMaxWidth;

	&:has(.aDropdownBtn[aria-expanded='true']),
	&:has(.categoryDropDowns.showOnFocus) {
		border-bottom-color: transparent;
	}
}

#lpHeader-hdrPortal {
	box-sizing: content-box;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	max-width: $lpHeaderMaxWidth;
	min-width: $lpContainerMinWidth;
}

#lpHeader-pro {
	@include responsiveWidthLayout($lpContainerMinWidth, $lpHeaderMaxWidth);
	font: 400 1.4rem var(--fontHeading);

	@media (max-width: 1024px) and (orientation: portrait) {
		width: 100%;
	}

	&.Professional {
		max-width: none;
		width: 100%;
	}

	&.Professional.employeeTool {
		background: #000;
		margin-bottom: 5px;
		width: 100%;

		.header-wrapper-black {
			box-sizing: content-box;
			margin: 0 auto;
			max-width: 1920px;
			min-width: 960px;
			width: calc(100% - 60px);

			#lpHeader-hdrPortal {
				padding: 1.5rem 0;
			}

			.hdrPortal-portalLink > a,
			.storeLabel,
			.headerDropDowns-title {
				color: #fff;
			}

			button.headerButton {
				&:hover {
					height: 24px;
				}
			}
		}

		& + .Professional {
			#lpHeader-hdrWrapper {
				.logoWrapper {
					margin-top: 5px;
				}
			}
		}
	}

	input[type='text'] {
		@include headerTextInputsStyle;
	}

	#lpHeader-hdrPortal {
		@media (min-width: 1367px) {
			padding: 1.3rem 0 0.65rem;
		}

		/* iPad Pro resolution is 1366x1024 */
		@media (max-width: 1366px) and (orientation: portrait) {
			padding: 1.3rem 3rem 0.65rem;
		}

		@media (max-width: 1366px) and (orientation: landscape) {
			padding: 1.3rem 0 0.65rem;
		}

		@media (max-width: 1024px) and (orientation: portrait) {
			padding: 1.3rem 1rem 0.65rem;
		}
	}
}

.freeShippingHeader {
	cursor: pointer;
	outline-offset: 2px;
}

.hdrPortal-portalLinks {
	align-items: center;
	display: flex;
	font-size: 1.2rem;
	list-style: none;
	margin: 0;
	padding: 0;
	text-transform: uppercase;

	.hdrPortal-portalLink {
		min-width: 35px;

		@media (min-width: 1280px) {
			font-size: 1.4rem;
		}
	}

	a {
		color: black;
		padding: 0;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}
}

.hdrPortal-portalLink {
	display: none;
	height: $headerNavDdAnchorHeight;
	line-height: $headerNavDdAnchorHeight;
	margin-left: var(--hdrPortal-portalLink-margin);
	text-align: center;
	transition: transform 5ms ease-out;

	&:not(:empty) {
		display: block;
	}

	.cartOverviewLinks & {
		margin-left: var(--lpHeader-hdrWrapper-margin);
	}
}

#liveHeaderChatLink {
	position: relative;

	.contactUs-menu {
		left: calc(100% - 232px);
		width: 200px;

		&--item {
			border-bottom: 1px solid #bbb;
			font-weight: 500;
			margin: 0;
			text-align: center;
			text-transform: uppercase;

			&:first-child {
				padding-bottom: 10px;
			}

			&:last-child {
				border-bottom: 0;
				padding-top: 10px;
			}

			&:only-child {
				padding: 0;
			}
		}
	}

	.chatButtonContainer {
		height: 100%;
		left: 0;
		text-indent: 0;
		top: 0;
		width: 100%;
		z-index: 1;

		button {
			background: transparent;
			border: none;
			display: inline-block;
			margin-left: 0;
			text-transform: uppercase;

			@media only screen and (max-width: 1280px) {
				font-size: inherit;
			}
		}
	}

	.chatButtonContainer.bold {
		font-weight: 500;
	}

	&.canChat {
		.chatButtonContainer {
			display: flex;
		}
	}
}

#pnlLoggedOut {
	// for Desktop account popup hide/show
	.isDesktop.signInContainer {
		border: none;
		display: block;
		padding: 0;
		visibility: hidden;

		&.isOpened {
			visibility: visible;
		}
	}

	.signInContainer {
		left: initial;
		position: absolute;
		right: -15px;
		top: 26px;
		width: 230px;
	}

	// for iPad account popup hide/show
	.isIPad.signInContainer {
		display: none;
	}

	&.hover .isIPad.signInContainer {
		display: block;
	}
}

#pnlLoggedOut.hdrPortal-portalLink {
	display: flex;
}

#userNotifications.hdrPortal-portalLink {
	margin: 0;
}

.storeLabel {
	margin-left: 17px;
}

// END: LP Header Top Section

// START: LP Header Top Nav Portal Features
$signInContainerZIndex: 9999;

.signInContainer {
	transition: visibility 5ms 5ms;
	z-index: $signInContainerZIndex;
}

#storeNumber {
	align-items: center;
	display: flex;
	margin-right: var(--hdrPortal-portalLink-margin);

	input[type='text'] {
		border-right: 1px solid #bbb;
		font-size: 1.2rem;
		height: 25px;
		margin-left: 5px;
		margin-right: 5px;
		text-align: center;
		width: 45px;
	}

	.storeUpdate {
		&::-moz-focus-inner {
			border: 0;
			padding: 0;
		}

		&.calloutBtn {
			font-size: 11px;
			line-height: 14px;
			margin: -2px 0 0 6px;
			padding: 1px 5px;
			text-shadow: none;
		}
	}
}

#userAccount {
	.headerDropDowns-title {
		margin-left: 0;
		max-width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.headerDropDowns-menu {
		font-size: 1.4rem;
		font-weight: 400;
		left: auto;
		min-width: 130px;
		right: 0;
		text-transform: capitalize;
		transition: visibility 5ms 5ms;
	}
}

// END: LP Header Top Nav Portal features

// START: LP Header Main Content Wrapper
#lpHeader-hdrWrapper {
	display: flex;
	justify-content: space-between;
	position: relative;

	> div,
	> a {
		margin: 5px 0;
	}

	.tagline {
		color: #333;
		display: block;
		font-size: 12px;
		font-weight: normal;
		margin-top: 8px;
		text-decoration: none;

		&.taglineCart {
			font-size: 9.5px;
		}
	}

	.logoWrapper {
		outline-offset: 4px;
		text-decoration: none;

		&__globalLogo {
			width: 248px;

			&--holiday {
				margin-bottom: -9px;
				margin-top: -9px;

				@media only screen and (max-width: 1189px) {
					margin-top: -4px;
				}
			}
		}
	}
}

#lpHeader-hdrWrapper--right {
	align-items: center;
	display: flex;
	flex: 1;
	justify-content: flex-end;
	z-index: 15;
}

// END: LP Header Main Content Wrapper

// START: LP Header Search
#search {
	&:focus {
		outline-offset: -2px;
	}
}

.searchContainer {
	--search-input-offset-left: 0px; // needs unit for calc()
	@include searchContainer;

	#lpHeader-hdrWrapper & {
		margin-right: 0;

		&--sticky {
			align-items: center;
			animation: slideDown 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s 1;
			background: #fff;
			box-shadow: 0 4px 10px -2px rgba(102, 102, 102, 0.3);
			display: flex;
			height: fit-content;
			justify-content: center;
			left: 0;
			margin-left: 0;
			max-height: 180px;
			padding: 16px 0;
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 3;

			@keyframes slideDown {
				from {
					transform: translate3d(0, -200%, 0);
				}

				to {
					transform: translate3d(0, 0, 0);
				}
			}

			&.searchContainer--noAnimation {
				animation: none;
			}

			.ie11-wrapper {
				left: var(--search-input-offset-left);
				top: 78%;
			}
		}
	}

	.searchInput {
		@include searchInput;
		border-radius: 5px 0 0 5px;
		border-color: #333;

		&::placeholder {
			color: #595959;
			opacity: 1;
		}
	}

	.searchInputResponsive {
		background: #fff;

		&:focus {
			outline-offset: -2px;
		}
	}

	.searchArrowBtn {
		@include searchArrowBtn;
		border-radius: 0 5px 5px 0;
		background: #333;
		border-color: #333;
		&::before {
			color: #fff;
		}
	}

	.sr-only {
		height: 1px;
		left: -10000px;
		overflow: hidden;
		position: absolute;
		top: auto;
		width: 1px;
	}
}

// END: LP Header Search

// START: LP Header Inspiration and Saved
#portfolioContainer {
	align-items: center;
	display: flex;
	font-size: 1.4rem;
	justify-content: space-between;
	text-transform: uppercase;

	.headerDropDowns-title {
		margin: -3px 0 -1px;
		outline-offset: 2px;
		padding: 5px 0 1px 2px;
	}

	&.long {
		width: 364px;
	}

	.portfolioContainer-feature {
		margin-left: var(--lpHeader-hdrWrapper-margin);

		&.hover,
		&:not(.isTouch):hover .headerDropDowns-title {
			border-bottom: $categoryNavHoverBottomBorderWidth solid var(--header-nav-hover-line-color);
			margin-bottom: -$categoryNavHoverBottomBorderWidth;
		}

		&.calloutBtn {
			margin-right: 0;
		}

		&.calloutBtn:hover {
			margin-bottom: 0;
		}
	}
}

#portfolioContainer-savedPortfolio {
	display: inline-block;
	white-space: nowrap;

	.headerDropDowns-menu {
		font-size: 1.3rem;
		padding: 10px;
		top: 29px;

		@media (min-width: 1280px) {
			font-size: 1.4rem;
		}
	}
}

#savedPortfolio-totalSaved {
	align-items: center;

	&--container {
		display: inline-block;
		height: $heartIconSize;
		margin: -3px 2px -1px -2px;

		position: relative;
		vertical-align: top;
		white-space: nowrap;
		width: $heartIconSize;

		@media (max-width: 1100px) {
			margin: -4px 2px -1px -2px;
		}

		span:last-child {
			margin: 0;
		}
	}

	&--totalSavedCount {
		color: #fff;
		font-size: 1.1rem;
		font-weight: 500;
		height: $heartIconSize;
		left: 0;
		line-height: $heartIconSize - 0.2rem;
		position: absolute;
		text-align: center;
		top: 0;
		width: $heartIconSize;
	}

	&.zeroSaved {
		.lpIcon-favorite {
			display: inline-block;
		}

		#savedPortfolio-totalSaved--icon,
		.savedPortfolio-totalSaved-hospitality {
			display: none;
		}
	}
}

#portfolioContainer-savedPortfolio {
	&.notTouch:hover .headerDropDowns-title {
		padding-bottom: 0;
	}

	.headerDropDowns-title::before {
		top: 8px;
	}

	.headerDropDowns-menu {
		left: 0;
		padding: 15px;
		text-align: left;

		a {
			text-decoration: none;
		}

		a:hover {
			color: var(--red);
			text-decoration: underline;
		}
	}

	.lpIcon-favorite {
		display: none;
		font-size: $heartIconSize;
	}

	.lpIcon-favoriteselected {
		color: $highlightRed;
		font-size: $heartIconSize;
	}

	.savedPortfolio {
		&--link,
		&--link:hover {
			color: #000;
			font-weight: 500;
			text-transform: capitalize;
		}

		&--link.zeroRoom,
		&--link.zeroWishlist {
			font-weight: normal;
		}
	}
}

.portfolioContainer-savedPortfolioWrapper {
	margin-left: var(--lpHeader-hdrWrapper-margin);
	position: relative;

	#portfolioContainer-savedPortfolio {
		margin-left: unset;
	}
}

.savedNotification {
	.savedNotificationLink {
		background: #fff;
		border-radius: 5px;
		box-shadow: 0 3px 6px 1px #bbb;
		box-sizing: border-box;
		display: flex;
		height: 66px;
		left: 50%;
		margin: auto;
		position: absolute;
		text-decoration: none;
		transform: translate(-50%, 12px);

		&::before {
			background: #fff;
			border-bottom: 1px solid #999;
			border-right: 1px solid #999;
			content: '';
			height: 16px;
			left: 50%;
			position: absolute;
			top: 0;
			transform: rotate(225deg) translate(4px, 8px);
			width: 16px;
		}

		&.hospitalityNotification {
			left: 0;

			&::before {
				left: 65%;
			}
		}
	}

	.savedNotificationIcon {
		align-items: center;
		background: #b60610;
		border-radius: 5px 0 0 5px;
		box-sizing: border-box;
		display: flex;
		height: 100%;
		padding: 4px 8px;

		.lpIcon-favoriteselected {
			color: #fff;
			display: block;
			font-size: 20px;
		}
	}

	.savedNotificationInfo {
		align-items: center;
		border: 1px solid #999;
		border-left: none;
		border-radius: 0 5px 5px 0;
		display: flex;
		font-size: 16px;
		padding: 5px 25px;
		text-transform: initial;
		width: 160px;
	}
}

#portfolioContainer-needHelpCart {
	display: none;
}

// END: LP Header Inspiration and Saved

// START: LP Header Cart Button
.headerCart {
	align-items: center;
	display: flex;
	height: 30px;
	margin: -2px 0 0 var(--lpHeader-hdrWrapper-margin);
	text-decoration: none;

	.notZero & {
		margin-top: -6px;
		padding-top: 4px;
	}

	&:focus,
	&:hover {
		color: #000;
		text-decoration: none;
	}

	&__iconWrapper {
		margin-right: 15px;
		position: relative;
		z-index: 1;
	}

	&__icon {
		color: #000;
		font-size: 2.8rem;
	}

	&__count {
		align-items: center;
		background-color: var(--red);
		border: 2px solid var(--baseContrastTextColor);
		border-radius: 50%;
		color: var(--baseContrastTextColor);
		display: flex;
		font-size: 1rem;
		height: 18px;
		justify-content: center;
		line-height: 18px;
		position: absolute;
		right: -12px;
		top: -5px;
		width: 18px;

		&.hidden {
			display: none;
		}
	}

	&__count--99plus {
		border-radius: 15px;
		width: 24px;
	}
}

// END: LP Header Cart Button

// START: LP Header Nav Content
#lpHeader-navWrapper {
	height: $navWrapperHeight;
	margin: 0 auto;
	max-width: 1280px;
	width: 100%;
}

#lpHeader-navWrapper--nav {
	box-sizing: content-box;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	height: $navWrapperHeight;
	justify-content: space-between;
	list-style-type: none;
	margin: 0;
	-webkit-tap-highlight-color: transparent;
	z-index: 11;

	.aNavBtn {
		background: none;
		border: 0;
		color: #000;
		display: inline-block;
		font: 500 #{1.4rem}/ $navAnchorHeight var(--fontHeading);
		letter-spacing: -0.01em;
		padding: 0;
		position: relative;
		text-align: center;
		text-decoration: none;
		white-space: nowrap;
		width: 100%;

		&--highlight,
		&--highlight ~ .categoryDropDowns .categoryDropDowns__mainTitle {
			color: var(--header-nav-highlight-color);
		}

		@media (max-width: 1180px) and (orientation: portrait) {
			font-size: 1.3rem;
		}

		@media (min-width: 1112px) {
			font-size: 1.5rem;
		}

		@media (min-width: 1280px) {
			letter-spacing: -0.02em;
		}
	}

	.notTouch:hover .aNavBtn,
	.selected .aNavBtn,
	.aNavBtn.hover {
		border-bottom: $categoryNavHoverBottomBorderWidth solid var(--header-nav-hover-line-color);
	}

	.notTouch:hover .aDropdownBtn {
		bottom: 7px;
		position: relative;
	}

	.notTouch.selected,
	.notTouch.selected:focus {
		.aDropdownBtn {
			bottom: 5px;
			position: relative;
		}
	}

	.aDropdownBtn {
		background: none;
		border: 1px solid #bbb;
		display: block;
		height: 14px;
		margin: auto;
		margin-top: -11px;
		opacity: 0;
		pointer-events: none;

		&--focused {
			opacity: initial;
			pointer-events: initial;
		}

		.caret.down {
			margin-bottom: 6px;
		}

		.caret {
			height: 5px;
			width: 5px;
		}
	}

	> li {
		cursor: pointer;
		flex-grow: 1;
		padding: 0 10px;
		text-align: center;

		// iPad
		&:not(.notTouch) {
			.categoryDropDowns {
				visibility: hidden;
			}

			.aNavBtn.hover ~ .categoryDropDowns {
				visibility: visible;
			}
		}

		// Desktop / Laptop
		&.notTouch {
			& .categoryDropDowns {
				display: none;
				transition: visibility 150ms 150ms;
			}

			&:hover .categoryDropDowns {
				display: block;

				&.showOnFocus {
					margin-top: -2px;
				}
			}

			& .categoryDropDowns.showOnFocus {
				display: block;
				margin-top: 3px;
			}

			& .categoryDropDowns.hideForClick {
				display: none;
			}
		}
	}
}

#lpHeader-hdrWrapper {
	#lpHeader-navWrapper {
		.notTouch:hover .aDropdownBtn {
			bottom: 3px;
		}
	}
}

// END: LP Header Nav Content

// START: LP Header dropdown styles
.headerDropDowns {
	box-sizing: content-box;
	display: inline-block !important;
	position: relative;

	&.saleMenuContainer-feature {
		position: unset;
	}

	.headerDropDowns-title {
		color: #444;
		cursor: pointer;
		display: inline-block;
		height: $headerNavDdAnchorHeight;
		text-align: left;
		text-decoration: none;

		&:not(#userName) {
			margin-top: -1px;
		}

		&:hover {
			color: #000;
		}
	}

	&.hover,
	&:not(.isTouch):hover {
		padding-bottom: 0;
		padding-top: 0;
	}

	&.hover .headerDropDowns-menu,
	&:not(.isTouch):hover .headerDropDowns-menu {
		visibility: visible;
	}

	&.hover,
	&:not(.isTouch):hover .headerDropDowns-title {
		border-bottom: $categoryNavHoverBottomBorderWidth solid var(--header-nav-hover-line-color);
		margin-bottom: -$categoryNavHoverBottomBorderWidth !important;
		visibility: visible;
	}

	&.hover,
	&:not(.isTouch):hover .headerButton {
		height: 25px;
	}

	.headerDropDowns-menu-visible {
		visibility: visible !important;
	}

	.headerDropDowns-title-visible {
		border-bottom: $categoryNavHoverBottomBorderWidth solid var(--header-nav-hover-line-color);
		margin-bottom: -$categoryNavHoverBottomBorderWidth;
		visibility: visible;
	}

	.headerDropDowns-menu {
		background-color: #fff;
		border: 1px solid #969291;
		left: 0;
		padding: 15px;
		position: absolute;
		text-transform: none;
		top: $headerNavDdWrapperHeight;
		transition: visibility 150ms 150ms;
		visibility: hidden;
		z-index: 9999;

		a:hover {
			color: #000;
		}
	}

	.headerDropDowns-menu-list {
		box-shadow: none;
		clear: both;
		list-style: none;
		margin: 0;

		&--item {
			margin-bottom: 10px;
			text-align: left;
		}

		&--item:last-child {
			margin-bottom: 0;
		}

		.savedPortfolio--link {
			align-items: center;
			display: flex;

			&:hover {
				text-decoration: none !important;

				> div {
					text-decoration: underline;
				}
			}
		}

		&--icon {
			display: block !important;
			font-size: 1.6rem !important;
			margin-right: 10px;
		}
	}
}

.headerDropDowns-arrow {
	@include headerheaderDropDownArrow;
}

#hdrSignIn.headerDropDowns-title::before {
	content: none;
}

#hdrSignIn {
	background: transparent;
	border: none;
	color: #444;
	cursor: pointer;
	display: inline-block;
	height: 20px;
	padding: 0;
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;

	&.isActive {
		border-bottom: $categoryNavHoverBottomBorderWidth solid var(--header-nav-hover-line-color);
		height: 25px;
		margin-bottom: -$categoryNavHoverBottomBorderWidth;
	}

	@media only screen and (max-width: 1280px) {
		font-size: inherit;
	}
}

// END: LP Header dropdown styles

// START: LP Header Nav category dropdowns
.categoryDropDowns {
	background-color: #fff;
	border: 1px solid transparent;
	box-shadow: 0 4px 10px -2px rgba(102, 102, 102, 0.3);
	box-sizing: border-box;
	cursor: auto;
	font-family: var(--fontHeading);
	left: 0;
	margin-top: -2px;
	padding: 0 calc((100% - 1280px) / 2);
	position: absolute;
	width: 100%;
	z-index: 4;

	.hover + & {
		display: block;
	}

	&__row {
		display: flex;
		padding: 35px 25px;

		@media (min-width: 1025px) and (max-width: 1280px) {
			padding: 35px;
		}

		@media (max-width: 768px) {
			padding: 35px 15px;
		}

		#portfolioContainer-inspiration & {
			justify-content: center;
		}

		* {
			box-sizing: border-box;
		}

		&--spaceBottom {
			min-height: 400px;
		}
	}

	&__column {
		--category-dropdown-column-gap: 55px;
		margin-right: var(--category-dropdown-column-gap);
		padding-right: var(--category-dropdown-column-gap);

		#portfolioContainer-inspiration & {
			--category-dropdown-column-gap: 24px;
		}

		&:has(+ .categoryDropDowns__column--splash) {
			margin-right: 0;
		}

		&:not(:has(+ .categoryDropDowns__column--splash), :last-child) {
			border-right: 1px solid #d9d9d9;
		}

		@media (max-width: 1281px) {
			--category-dropdown-column-gap: 50px;
		}

		@media (max-width: 1142px) {
			--category-dropdown-column-gap: 40px;
		}

		@media (max-width: 1024px) {
			--category-dropdown-column-gap: 48px;
		}

		@media (max-width: 980px) {
			--category-dropdown-column-gap: 45px;
		}

		@media (max-width: 960px) {
			--category-dropdown-column-gap: 40px;
		}

		@media (max-width: 890px) {
			--category-dropdown-column-gap: 30px;
		}

		#portfolioContainer-inspiration & {
			@media (max-width: 1382px) {
				--category-dropdown-column-gap: 50px;
			}

			@media (max-width: 1335px) {
				--category-dropdown-column-gap: 40px;
			}

			@media (max-width: 1295px) {
				--category-dropdown-column-gap: 30px;
			}

			@media (max-width: 1275px) {
				--category-dropdown-column-gap: 20px;
			}
		}

		&:last-child {
			margin-right: 0;
			padding-right: 0;
		}

		&--splash {
			display: flex;
			flex-grow: 1;
			justify-content: right;

			.dropDownSplash:not(:last-child) {
				margin-right: 20px;

				@media (max-width: 1033px) {
					margin-right: 15px;
				}

				@media (max-width: 768px) {
					margin-right: 5px;
				}
			}

			img {
				display: block;
				height: auto;
				max-width: 240px;

				&.salesDropdownBanner--wide {
					max-width: 365px;
				}
			}
		}
	}

	&__mainTitle {
		color: #000;
		font: 500 4.5rem/1.2 var(--fontSerif);
		margin-bottom: 20px;
		text-align: left;

		@media (max-width: 1231px) {
			font-size: 3.5rem;
		}

		@media (max-width: 1205px) {
			font-size: 3rem;
		}

		@media (max-width: 1182px) {
			font-size: 2.8rem;
		}

		@media (max-width: 1173px) {
			font-size: 2.5rem;
		}
	}

	&__list {
		--category-dropdowns-list-line-height: 1.5rem;
		--category-dropdowns-list-padding-bottom: 1.4rem;
		display: flex;

		@media (min-width: 1280px) {
			--category-dropdowns-list-line-height: 1.9rem;
		}

		li {
			font-size: 1.3rem;
			line-height: var(--category-dropdowns-list-line-height);
			padding-bottom: var(--category-dropdowns-list-padding-bottom);
			text-align: left;

			&:last-child {
				padding-bottom: 0;
			}

			@media (min-width: 1280px) {
				font-size: 1.6rem;
			}

			&:first-child {
				font-weight: 500;
			}

			a {
				display: inline-block;
				font-size: inherit;
				line-height: inherit;
			}
		}

		&--noHeading {
			li:first-child {
				font-weight: normal;
			}
		}

		&--spaceTop {
			margin-top: calc(var(--category-dropdowns-list-line-height) + var(--category-dropdowns-list-padding-bottom));
		}

		&:not(:last-child) {
			margin-bottom: 30px;
		}

		a {
			color: #000;
			display: block;
			padding: 0;
			text-decoration: none;

			&:hover {
				color: var(--red);
				text-decoration: underline;

				&:has(span) {
					text-decoration: none;

					span:not([aria-hidden='true']) {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&__list--centered {
		align-items: center;
	}

	&__mainList {
		list-style: none;
		margin: 0;

		&--horiz {
			display: flex;
		}

		&--twoColumns {
			column-count: 2;
		}

		&--compact {
			margin-top: -5px;

			li {
				line-height: 2.8rem;
			}
		}
	}

	&__subList {
		list-style: none;
		margin: 0;
		padding-left: 20px;
	}

	&__icon {
		margin-right: 10px;
		width: 50px;

		img {
			display: block;
			height: auto;
		}
	}

	&__imgSmall {
		display: flex;

		img {
			max-width: 200px;
		}
	}

	&__saleLinks {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		h2 {
			letter-spacing: normal;
			margin: 0;
		}

		a {
			display: block;
			font-size: 1.6rem;
			font-weight: bold;
			line-height: 1.2;
			padding: 5px 0;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__tagLine {
		color: #000;
		cursor: auto;
		font-size: 97%;
		font-weight: normal;
		margin-top: 5px;
	}

	.aDropdownClose {
		background: none;
		border: 0;
		color: #333;
		font-family: var(--fontBody);
		font-family: -webkit-body;
		font-size: 16px;
		height: auto;
		padding: 5px 5px 0;
		position: absolute;
		right: 0;
		text-indent: 0;
		text-transform: uppercase;
		top: 0;
		width: auto;
	}
}

// END: LP Header Nav category dropdowns

// COUPON MODAL
#couponOverlay {
	background: #c00;
	padding: 0;

	.couponOuterDiv {
		background: #fff;

		#couponHdr {
			color: #980101;
			font-size: 24px;
		}

		#couponDescp {
			span {
				display: block;
				margin-top: 1rem;
			}
		}

		#couponSmallPrintContainer {
			padding: 16px 0 0;
			text-align: left;

			h3 {
				font-size: 22px;
				font-weight: 500;
			}

			#couponSmallPrint {
				display: inline-block;
				font-size: 12px;
				height: 150px;
				overflow: hidden;

				&.scrollCouponPrint {
					overflow-y: scroll;
				}
			}
		}
	}
}

#couponOuterContainer {
	margin: 5px 0 0;
	position: relative;
}

.couponContainer {
	text-align: left;
	width: 100%;
}

#couponHdrContainer {
	background-color: #c00;
	height: 130px;
	text-align: center;
	vertical-align: middle;
}

#couponHdr {
	color: #fff;
	font-size: 22px;
	line-height: 28px;
}

#couponHdrExt {
	background-color: #c00;
}

#couponDesc {
	background-color: #f1f1f1;
	height: 130px;
	padding: 0 0 15px;
}

#couponDesc p {
	margin-left: 20px;
	margin-top: 10px;
	width: 330px;
}

#couponDescExt {
	background-color: #f1f1f1;
}

#couponTermsContainer {
	background-image: url('/images/homepage1/couponTandCBackground.gif');
	height: 236px;
	left: 390px;
	position: absolute;
	top: 15px;
	width: 387px;
}

#couponTerms {
	height: 216px;
	margin: 10px auto;
	overflow-x: hidden;
	overflow-y: auto;
	width: 360px;
}

#couponTerms p {
	font-size: 10px;
	margin: 0;
	padding: 0;
	text-align: left;
}

#couponSmallPrintContainer {
	padding: 8px;
	text-align: center;
}

#couponSmallPrint {
	color: #000;
	cursor: auto;
	font-size: 10px;
}

.border2 {
	border: 2px solid #ccc;
}

#couponError {
	background-color: #fff;
	height: 130px;

	&[align='left'] .couponErrorMessage {
		width: 310px;
	}
}

#couponError h1 {
	color: #c00;
	font-size: 20px;
	line-height: 28px;
	margin: 25px 0 0;
	padding: 10px 25px;
}

// END: Coupon modal

/* Our custom style for pagination controls using the Pagination jQuery plugin (classes with hyphens are generated by the plugin) */
.paginationControls {
	font: 14px var(--fontHeading);

	ul {
		list-style: none;
		margin: 0;
	}

	li {
		display: inline-block;
	}

	.paginationjs-page {
		border-radius: 100%;
		cursor: pointer;
		height: 30px;
		line-height: 30px;
		margin-left: 5px;
		text-align: center;
		width: 30px;

		&.active {
			background: #dcdcdc;
			font-weight: 500;
		}

		a {
			display: inline-block;
			height: 100%;
			text-decoration: none;
			width: 100%;
		}

		&:not(.active):hover {
			background-color: #f2f2f2;
		}
	}

	.paginationjs-next {
		margin-left: 8px;
	}

	.paginationjs-next,
	.paginationjs-prev {
		.caret {
			@include caret($size: 12px);
			margin-bottom: -2px;
		}

		&.disabled {
			visibility: hidden;
		}
	}

	.paginationjs-ellipsis a {
		cursor: default;
	}
}

.altHeaderSignIn {
	align-items: flex-end;
	display: flex;
	padding-right: 40px;
	text-align: right;

	&__inner {
		line-height: 1.4;
		margin-bottom: 3px;
	}

	&__callout {
		&--small {
			font-size: 1.1rem;
		}
	}
}

@import 'print';

head iframe {
	display: none;
}

.isHospitality {
	form#ctl02 {
		.hdrTopCallout-calloutMsg {
			background-color: #afb7c4;
			font-size: 1.2rem;
			font-weight: 400;
			margin: 0 auto;
			padding: 5px 0;
			text-align: center;
			text-transform: uppercase;

			@media (min-width: 1280px) {
				font-size: 1.4rem;
			}

			.hdrTopCallout-calloutMsg--deal {
				font-weight: 500;
			}
		}

		#hospitalityMaskSearchMode {
			border: 1px solid #bbb;
			color: #666;
			font-size: 1.4rem;
			height: 40px;
			line-height: 24px;
			margin-right: 2px;
			padding: 2px 5px 2px 8px;
			width: 80px;
		}
	}
}

.headerButton {
	background: transparent;
	border: none;
	color: #444;
	cursor: pointer;
	display: inline-block;
	font-size: inherit;
	height: 20px;
	padding: 0;
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;

	&.isActive {
		border-bottom: $categoryNavHoverBottomBorderWidth solid var(--header-nav-hover-line-color);
		height: 25px;
		margin-bottom: -$categoryNavHoverBottomBorderWidth;
	}
}

.promoBanner {
	align-items: stretch;
	background: #2b3064;
	color: #fff;
	display: flex;
	padding: 2px 0;
	position: relative;

	&--minka {
		background: #35641b;
	}

	&--homepage {
		margin-bottom: 20px;
	}

	&__content {
		align-items: center;
		display: flex;
		flex-grow: 1;
		gap: 20px;
		justify-content: center;
		line-height: 1.2;
		min-width: 900px;
		text-decoration: none;
	}

	&__text {
		align-items: center;
		color: #fff;
		display: flex;
		font-size: 1.6rem;
		gap: 20px;
		margin: 0;
		text-align: center;
	}

	&__shop {
		color: #fff;
		font-size: 1.6rem;
		font-weight: 500;
		text-decoration: none;

		&--pipe {
			border-left: 1px solid #fff;
			padding-left: 20px;
		}
	}

	&__center {
		border-left: 1px solid #fff;
		border-right: 1px solid #fff;
		padding: 0 20px;
	}

	&__terms {
		font-size: 1.1rem;
		padding-left: 5px;
	}

	&__close {
		color: #fff;
		font-size: 3.5rem;
		text-decoration: none;
		width: 40px;

		&:hover {
			color: #fff;
			opacity: 0.7;
		}
	}
}

#savedHeaderMenu {
    box-sizing: content-box;
	text-transform: uppercase;
}