@import '../../global/style-guide';

.toggleDropDown {
	display: inline-block;
	position: relative;

	&.closed .dropDown {
		display: none;
	}

	.dropDown {
		background: #fff;
		border: 4px solid #666;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
		color: #000;
		left: 0;
		padding: 0 4px;
		position: absolute;
		top: 100%;
		z-index: 20;
	}
}

#userNotifications {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 0;
	position: relative;
	vertical-align: top;

	&:after {
		content: '';
		display: inline-block;
		height: 17px;
		margin-left: 10px;
		vertical-align: top;
	}

	&.hidden {
		display: none;
	}

	.notifMenuItem {
		background: #414141;
		box-sizing: border-box;
		position: relative;
		z-index: 21;
	}

	&.closed {
		.notifMenuItem {
			background: transparent;
		}

		hr {
			display: none;
		}
	}

	.indicator {
		background: #ccc;
		border: 1px solid #fff;
		border-radius: 50%;
		display: block;
		height: 18px;
		margin: 0 auto;
		width: 18px;
	}

	&.connected {
		.notifMenuItem {
			cursor: pointer;
		}
	}

	&.hasNewNotifications {
		.indicator {
			background: #0c0;
		}

		.count {
			display: block;
			left: 0;
			top: 1px;
			width: 14px;
		}
	}

	.count {
		background: #c00;
		border-radius: 10px;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
		color: #fff;
		display: none;
		font-size: 1.2rem;
		font-weight: 500;
		height: 18px;
		left: 7px;
		line-height: 18px;
		overflow: hidden;
		padding: 0 3px;
		position: absolute;
		text-align: center;
		top: 0px;
		width: 18px;
	}

	hr {
		border-top: 2px solid #414141;
		height: 0;
		position: absolute;
		width: 186px;
	}
}
#noNotifications {
	padding: 8px;
	text-align: center;
}
#notifPanel {
	background: #fff;
	border: 1px solid #969291;
	border-top: 2px solid #444;
	box-sizing: border-box;
	font-size: 0.95em;
	line-height: 12px;
	overflow-y: auto;
	position: absolute;
	top: 25px;
	width: 450px;
	z-index: 1000;

	&.alignRight {
		left: auto;
		right: 0;
	}
}
#notifPanel,
#notificationsPage {
	a:not(.disabled) {
		color: #000;
		text-decoration: underline;
	}
	ul {
		background-color: #fff;
		list-style: none;
		margin: 0;
		padding: 8px 0;
	}
	li {
		align-items: flex-end;
		border-top: 1px solid #ccc;
		display: flex;
		justify-content: space-between;
		padding: 4px 4px 16px;
		text-align: left;

		&:first-child {
			border-top: 0;
		}
		&.new .date {
			color: $highlightRed;
		}
		.date {
			color: #999;
			font-weight: 500;
		}
		a {
			float: right;
		}
		p {
			line-height: 1.2em;
			margin: 0.2em 0;
		}
	}
	.notificationsRight {
		margin-left: 10px;
		
		a {
			margin-bottom: 4px;
		}
	}
	.loadMore {
		display: block;
		font-size: 12px;
		margin: 8px auto;
		text-align: center;
		&.hidden {
			display: none;
		}
	}
}
#notificationsBreadCrumb {
	padding: 15px 0 10px;
}
#notificationsPage {
	h1 {
		text-indent: 0.64em;
	}
	ul {
		list-style: none;
		margin: 15px;
		width: 60%;
		li {
			.date {
				display: block;
				font-size: 0.85em;
			}
			a {
				display: inline;
				line-height: 1.4em;
			}
			p {
				display: inline-block;
				line-height: 1.4em;
				margin: 0;
				width: 70%;
			}
		}
	}
	.pagination {
		width: 60%;
	}
}
