@import '_style-guide.scss';

#ftrSocialSection {
	border-bottom: 1px solid #ccc;
	box-sizing: border-box;
	margin-bottom: 15px;
	padding: 40px 10px 30px;

	.ftrSocialContainer {
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 1440px;
		min-width: 960px;
		width: calc(100% - 60px);

		@media screen and (max-width: 980px) {
			min-width: 0;
			width: calc(100% - 20px);
		}
	}

	.ftrDivider {
		background: #ccc;
		margin: 0 20px;
		width: 1px;
	}

	#ftrStayConnectedContainer {
		min-width: 540px;

		@media screen and (max-width: 1125px) {
			min-width: 400px;
		}
	}

	.ftrTop {
		align-items: center;
		box-sizing: border-box;
		display: flex;
		flex: 0 1 auto;

		.ftrLinkList-col {
			align-items: center;
			display: flex;
			justify-content: flex-end;
			margin: 0;
			width: 100%;
		}

		#ftrSubscribe .ftrSubscribeValidationMessage {
			box-sizing: border-box;
			width: 100%;
		}

		.lpForm {
			.flex-row {
				position: relative;
			}

			.field {
				position: unset;
			}
		}

		#txtEmailUpdatesRequest {
			border-color: #a9a9a9;
			height: 35px;
			padding-left: 10px;
			width: 100%;

			&:focus {
				outline-offset: -2px;
			}
		}

		#ftrSubscribeBtn {
			height: 35px;
			width: 40px;
			outline-offset: 0px;

			.lpIcon-scrollright {
				left: 16px;
				top: 5px;
				transition: 0.2s linear;
			}

			&:hover {
				background: #222;

				.lpIcon-scrollright {
					left: 17px;
				}
			}
		}

		.subscribeContainer {
			flex-basis: 400px;
			margin: 0;
			max-width: 400px;
		}

		.ftrLinkList-col--header {
			display: flex;
			flex-direction: column;
			font-size: 1.6rem;
			margin-bottom: 0;
			margin-right: 15px;
			text-align: right;
			white-space: nowrap;

			&Top {
				font-weight: 500;
				line-height: 1;
			}

			&Bottom {
				font-size: 1.2rem;
				font-weight: normal;
				text-transform: none;
			}
		}
	}
}

.ftrLinkList-col.communicationBlock {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;

	&::before {
		background-color: #ccc;
		content: '';
		height: 100%;
		left: -25px;
		position: absolute;
		width: 1px;
	}

	.ftrLinkList-col--item {
		a#ftrEmailUs {
			display: block;
			margin: 5px 0 0;

			.lpIcon-email {
				position: relative;
				top: 2px;
			}

			&:hover {
				text-decoration: none;
			}
		}

		#ftrChat {
			.chatWrapper.chatWrapperWithIcon {
				.chatButtonContainer {
					@media screen and (max-width: 1040px) {
						width: 50%;
					}
				}
				button {
					text-transform: capitalize;
					padding-left: 5px;
					font-size: 1.8rem;
					@media screen and (max-width: 1040px) {
						font-size: 1.15rem;
					}
					
					&::after {
						font-size: 2.3rem;
						@media screen and (max-width: 1040px) {
							font-size: 1.6rem;
							left: 0;
						}
					}
				}
			}
				
			div {
				&:first-child {
					padding-right: 8px;
				}

				@media (min-width: 1040px) {
					font-size: 18px;
				}
			}
		}
	
		#chatLink {
			.chatWrapper.chatWrapperWithIcon {
				button {
					&::after {
						font-size: 2.5rem;
						left: 2px;
						@media screen and (max-width: 1024px) {
							font-size: 16px;
							left: 0;
						}
					}
		
					&::before {
						font-size: 18px;
						left: 32px;
						text-transform: capitalize;
						@media screen and (max-width: 1024px) {
							font-size: 11.5px;
							left: 23px;
						}
					}
				}
			}
		}

		span {
			&:first-child {
				padding-right: 8px;
			}

			@media (min-width: 1040px) {
				font-size: 18px;
			}
		}
	}
}

// START: LP Footer Subscribe
#ftrSubscribe {
	position: relative;

	label,
	span.error {
		font-size: 1.45rem;
		font-weight: 500;
		margin: 0;
	}

	a {
		font-size: 0.9rem;
		margin-top: 9px;
	}

	.ftrSubscribeValidationMessage {
		background: #ffebeb;
		border: 1px solid #c00;
		bottom: calc(100% - 1px);
		color: #c00;
		font-size: 1.3rem;
		font-weight: 500;
		line-height: 1.3em;
		padding: 5px 6px;
		position: absolute;
		width: 257px;
	}

	.submitBtn {
		clear: none;
		float: left;
	}

	.validationGroup {
		margin: 0;
		position: relative;

		.flex-row {
			display: flex;
		}

		.field:first-child {
			flex-grow: 2;
		}

		.field {
			float: none;
			padding-bottom: 0;

			input {
				line-height: normal;
			}

			&:first-child {
				padding-right: 0;
			}

			&.submitBtn {
				width: 28px;

				.calloutBtn {
					padding: 0.5em 1em;
				}

				.lpIcon-scrollright {
					color: #fff;
					position: absolute;
					right: 36%;
					top: 15%;
				}
			}
		}
	}
}

#ftrSubscribeBtn {
	background: #000;
	border-radius: 0 5px 5px 0;
	display: block;
	height: 30px;
	position: relative;
	width: 30px;

	.lpIcon-scrollright {
		color: #fff;
		font-size: 1.4rem;
		font-weight: 500;
		left: 12px;
		margin: 0;
		position: absolute;
		top: 3px;
	}
}

#txtEmailUpdatesRequest {
	appearance: none;
	border: 1px solid #adaeaf;
	border-radius: 5px 0 0 5px;
	height: 30px;
	line-height: var(--baseLineHeight);
	padding: 0 0 0 5px;
	width: 237px;
}

// END: LP Footer Subscribe


#chatLink {
	height: 27px;
	margin-top: 5px;
	min-width: 150px;

	.callProMessage-contactOption {
		display: flex;
		flex-direction: column;
	}

	.chatWrapper {
		background: none;
		border: none;
		margin: 0;
		padding: 0;
		width: 150px;

		&.chatWrapperWithIcon {
			button {
				font-size: 1.8rem;
				&::before {
					font-weight: 400;
					left: 35px;
					top: 5px;
				}
	
				&::after {
					font-weight: 400;
					left: 10px;
					top: 4px;
				}
			}
		}

		.chatButtonContainer {
			width: 100%;

			button {
				display: block;
			}
		}

	}
}
#ftrChat {
	height: 27px;
	margin-top: 5px;
	min-width: 150px;

	.callProMessage-contactOption {
		display: flex;
		flex-direction: column;
	}

	.chatWrapper {
		background: none;
		border: none;
		margin: 0;
		padding: 0;
		width: 150px;

		&.chatWrapperWithIcon {

			button {
				background: transparent;
				border: none;
				display: block;
				font-size: 1.8rem;

				&::after {
					font-size: 1.8rem;
					font-weight: 400;
					left: 0;
					top: 4px;
				}
			}
		}

		.chatButtonContainer {
			width: 70%;
		}
	}
}

.ftrContainer {
	box-sizing: content-box;
	margin: 0 auto;
	margin: 0 auto;
	max-width: 1440px;
	min-width: 960px;
	padding: 15px 0;
	width: calc(100% - 60px);
}

.mobileSiteLinkContainer {
	font-weight: 500;
	margin-bottom: 40px;
	text-align: center;
}