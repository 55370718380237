/* Kiosk Printing Employee Snippet */
#kioskPrintHdr {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	margin: 10px auto 0;
	width: 670px;

	&.noProfile {
		margin-bottom: 40px;
	}
}

#kioskPrintStore {
	order: 3;
	padding: 7px 0 0;

	span {
		display: block;
		font-size: 1.1rem;
	}
}

#kioskPrintStoreAddress {
	font-size: 1.3rem;
	line-height: 16px;
	margin: 0 0 3px;
}

#kioskPrintStoreNum {
	display: inline !important;
	margin: 0 5px 0 0;
	text-transform: uppercase;
}

#kioskPrintStorePhone {
	display: inline !important;
}

#kioskPrintStoreEmp {
	order: 2;

	img {
		position: absolute;
		right: 20px;
		top: 20px;
	}
}

#kioskPrintStoreEmpInfo {
	margin: 0 12px 0 0;

	span {
		display: inline-block;
		font-size: 1.4rem;
		line-height: 16px;

		&:last-child::before {
			content: '-';
			margin-right: 4px;
		}
	}
}

#kioskPrintEmp {
	align-items: flex-start;
	display: flex;

	img {
		margin: 0 12px 0 0;
	}
}

#kioskPrintEmpNameEmail {
	font-size: 1.4rem;
}

#kioskPrintEmpPhone {
	display: block;
	font-size: 1.4rem;
	line-height: 18px;
	margin: 0 0 6px;
}

.kioskPrintEmpHourTitle {
	display: block;
	font-size: 1.1rem;
	text-transform: uppercase;
}

.kioskPrintEmpHours {
	display: block;
	font-size: 1.1rem;
	margin: 0 0 3px;
}

.kioskPrintHours {
	display: flex;

	.kioskPrintHour {
		margin-right: 30px;
	}
}
/* end Kiosk Printing Employee Snippet */
