@import '../mixins';

.layout-Sort {
	display: grid;
	gap: 0 8px;
	grid-template-columns: repeat(2, 1fr);
	margin: 0 auto;

	@media (min-width: 612px) and (orientation: landscape) {
		grid-template-columns: repeat(3, 1fr);
	}

	@include media(mobile) {
		max-width: 700px;
	}

	@include media(desktop) {
		gap: 0 46px;
		grid-template-columns: repeat(3, 1fr);
	}

	@include media(desktop1280) {
		grid-template-columns: repeat(4, 1fr);
	}
}
