// Prevent pixels from shifting layout from DOM injections
iframe[name='google_conversion_frame'],
img[name='hs_pix_es'] {
	height: 0;
	overflow: hidden;
	width: 0;
}

// BC chat window (ipad)
.touch #bc-chat-container {
	// FIX scaling issue
	left: auto !important;
	right: 0 !important;
	// FIX chat window shifting to the top when keyboard is visible
	&.bc-chat-state-chat {
		bottom: 0 !important;
		position: fixed !important;
		top: auto !important;
	}
}

// Hide the display of these pixels to prevent whitespace
#bluecore_purchase_pixel,
img[src*='mpp.vindicosuite.com'],
img[src*='secure.insightexpressai.com'] {
	display: none;
}
