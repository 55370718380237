%half-star {
	background-image: url('/img/review-stars/star-half.svg');
}
%filled-star {
	background-image: url('/img/review-stars/star-on.svg');
}

.goldReviewStars {
	--goldReviewStarsSize: 2.1rem;
	display: flex;
	justify-content: center;
	@for $i from 1 through 5 {
		&--#{$i}-0 &__star:nth-of-type(-n+#{$i}) {
			@extend %filled-star;
		}
	}

	@for $i from 0 through 4 {
		// '0-5' doesn't need any full stars
		@if ($i > 0) {
			&--#{$i}-5 &__star:nth-of-type(-n+#{$i}) {
				@extend %filled-star;
			}
		}

		&--#{$i}-5 &__star:nth-of-type(#{$i + 1}) {
			@extend %half-star;
		}
	}

	&__star {
		background-image: url('/img/review-stars/star-off.svg');
		background-repeat: no-repeat;
		height: var(--goldReviewStarsSize);
		width: var(--goldReviewStarsSize);
		&:not(:last-of-type) {
			margin-right: 0.3rem;
		}
	}
}