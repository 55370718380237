@font-face {
	font-display: block;
	font-family: 'LPIcons';
	font-style: normal;
	font-weight: normal;
	src: url('/Content/_universal/lpIcons/fonts/LPIcons.woff?vf9e5') format('woff');
}

[class^='lpIcon-'],
[class*=' lpIcon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'LPIcons' !important;
	-webkit-font-feature-settings: 'liga';
	-moz-font-feature-settings: 'liga=1';
	-moz-font-feature-settings: 'liga';
	-ms-font-feature-settings: 'liga' 1;
	font-feature-settings: 'liga';

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	-webkit-font-variant-ligatures: discretionary-ligatures;
	font-variant-ligatures: discretionary-ligatures;
	font-weight: normal;

	/* Enable Ligatures ================ */
	letter-spacing: 0;
	line-height: 1;
	speak: none;
	text-transform: none;
}

.lpIcon-star-selected::before {
	content: '\e900';
}

.lpIcon-star::before {
	content: '\e901';
}

.lpIcon-addframe::before {
	content: '\e902';
}

.lpIcon-addgraphic::before {
	content: '\e903';
}

.lpIcon-addphoto::before {
	content: '\e904';
}

.lpIcon-addtext::before {
	content: '\e905';
}

.lpIcon-alert::before {
	content: '\e906';
}

.lpIcon-back::before {
	content: '\e907';
}

.lpIcon-backtotop::before {
	content: '\e908';
}

.lpIcon-bulb01::before {
	content: '\e909';
}

.lpIcon-bulb02::before {
	content: '\e90a';
}

.lpIcon-calendar01::before {
	content: '\e90b';
}

.lpIcon-calendar02::before {
	content: '\e90c';
}

.lpIcon-call::before {
	content: '\e90d';
}

.lpIcon-cart::before {
	content: '\e90e';
}

.lpIcon-center::before {
	content: '\e90f';
}

.lpIcon-chat::before {
	content: '\e910';
}

.lpIcon-close01::before {
	content: '\e911';
}

.lpIcon-close02::before {
	content: '\e912';
}

.lpIcon-cloud::before {
	content: '\e913';
}

.lpIcon-color::before {
	content: '\e914';
}

.lpIcon-coupon::before {
	content: '\e915';
}

.lpIcon-crop01::before {
	content: '\e916';
}

.lpIcon-crop02::before {
	content: '\e917';
}

.lpIcon-delete01::before {
	content: '\e918';
}

.lpIcon-delete02::before {
	content: '\e919';
}

.lpIcon-delete03::before {
	content: '\e91a';
}

.lpIcon-directions::before {
	content: '\e91b';
}

.lpIcon-duplicate::before {
	content: '\e91c';
}

.lpIcon-edittext::before {
	content: '\e91d';
}

.lpIcon-effects::before {
	content: '\e91e';
}

.lpIcon-email::before {
	content: '\e91f';
}

.lpIcon-facebook-rev::before {
	content: '\e920';
}

.lpIcon-facebook::before {
	content: '\e921';
}

.lpIcon-favorite::before {
	content: '\e922';
}

.lpIcon-favoriteselected::before {
	content: '\e923';
}

.lpIcon-font::before {
	content: '\e924';
}

.lpIcon-fontsize::before {
	content: '\e925';
}

.lpIcon-handicapAccess::before {
	content: '\e928';
}

.lpIcon-help::before {
	content: '\e929';
}

.lpIcon-home::before {
	content: '\e92a';
}

.lpIcon-instagram-rev::before {
	content: '\e92d';
}

.lpIcon-instagram::before {
	content: '\e92e';
}

.lpIcon-lampsplus::before {
	content: '\e92f';
}

.lpIcon-Lplus-rev::before {
	content: '\e930';
}

.lpIcon-Lplus::before {
	content: '\e931';
}

.lpIcon-menu::before {
	content: '\e932';
}

.lpIcon-mobile::before {
	content: '\e933';
}

.lpIcon-more::before {
	content: '\e934';
}

.lpIcon-movebackward::before {
	content: '\e935';
}

.lpIcon-moveforward::before {
	content: '\e936';
}

.lpIcon-parking::before {
	content: '\e937';
}

.lpIcon-pinterest-rev::before {
	content: '\e938';
}

.lpIcon-pinterest::before {
	content: '\e939';
}

.lpIcon-plugin::before {
	content: '\e93a';
}

.lpIcon-preview::before {
	content: '\e93b';
}

.lpIcon-print::before {
	content: '\e93c';
}

.lpIcon-redo::before {
	content: '\e93d';
}

.lpIcon-rotate::before {
	content: '\e93e';
}

.lpIcon-save::before {
	content: '\e93f';
}

.lpIcon-scrollleft::before {
	content: '\e940';
}

.lpIcon-scrollright::before {
	content: '\e941';
}

.lpIcon-search::before {
	content: '\e942';
}

.lpIcon-secure::before {
	content: '\e943';
}

.lpIcon-share01::before {
	content: '\e944';
}

.lpIcon-share02::before {
	content: '\e945';
}

.lpIcon-startover01::before {
	content: '\e946';
}

.lpIcon-startover02::before {
	content: '\e947';
}

.lpIcon-store::before {
	content: '\e948';
}

.lpIcon-straighten::before {
	content: '\e949';
}

.lpIcon-success::before {
	content: '\e94a';
}

.lpIcon-textEdit::before {
	content: '\e94b';
}

.lpIcon-tool::before {
	content: '\e94c';
}

.lpIcon-twitter-rev::before {
	content: '\e94d';
}

.lpIcon-twitter::before {
	content: '\e94e';
}

.lpIcon-undo::before {
	content: '\e94f';
}

.lpIcon-user::before {
	content: '\e950';
}

.lpIcon-video-old::before {
	content: '\e951';
}

.lpIcon-video::before {
	content: '\e952';
}

.lpIcon-yelp-rev::before {
	content: '\e953';
}

.lpIcon-yelp::before {
	content: '\e954';
}

.lpIcon-googleplus::before {
	content: '\e948';
}

.lpIcon-youtube-rev::before {
	content: '\e957';
}

.lpIcon-youtube::before {
	content: '\e958';
}

.lpIcon-zoom01::before {
	content: '\e959';
}

.lpIcon-zoom02::before {
	content: '\e95a';
}

.lpIcon-open::before {
	content: '\e95b';
}

.lpIcon-brightness::before {
	content: '\e95d';
}

.lpIcon-saturation::before {
	content: '\e95e';
}

.lpIcon-contrast::before {
	content: '\e95f';
}

.lpIcon-loading::before {
	content: '\e960';
}

.lpIcon-erase::before {
	content: '\e961';
}

.lpIcon-megaphone::before {
	content: '\e962';
}

.lpIcon-tag::before {
	content: '\e963';
}

.lpIcon-partnership::before {
	content: '\e964';
}

.lpIcon-houzz-02::before {
	content: '\e965';
}

.lpIcon-houzz-rev-02::before {
	content: '\e966';
}

.lpIcon-linkedin::before {
	content: '\e967';
}

.lpIcon-linkedin-rev::before {
	content: '\e968';
}

.lpIcon-scaletofill::before {
	content: '\e969';
}

.lpIcon-duplicate02::before {
	content: '\e96a';
}

.lpIcon-room::before {
  content: '\e926';
}

.lpIcon-createaccount::before {
  content: '\e927';
}

.lpIcon-orderhistory::before {
  content: '\e92b';
}

.lpIcon-recentlyviewed::before {
  content: '\e92c';
}
