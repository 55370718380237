p.nobreak {
	margin-top: 0;
	padding-top: 0;
}

.serif {
	font-family: 'ChronicleDeckRoman', Baskerville, Palatino, 'Times New Roman', serif;
	font-weight: 400;
	letter-spacing: 0;
}

.upperCase {
	text-transform: uppercase;
}

.titleCase {
	text-transform: capitalize;
}

.sr-only {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
