@import '../global/_style-guide.scss';

.signInContainer {
	right: 0;
	position: absolute;
	top: 25px;

	.signInForm {
		background: #fff;
		outline: 1px solid #969291;
		padding: 1px;
		text-align: left;
		transition: height 0s 150ms linear;
		z-index: 9999;
	}
}

.inlineSignInContainer {
	position: relative;

	.signInFormLoader {
		height: 31px;
		left: 50%;
		margin: 0 0 0 -15.5px;
		position: absolute;
		top: 50%;
		width: 31px;

		&:before {
			animation: spin 1.25s infinite linear;
			content: '\e960';
			font-family: 'lpIcons';
			font-size: 2.5em;
			position: absolute;
			text-align: center;
			z-index: 99999;
		}
	}
}

.accountDropDown {
	background: #fff;
	font-family: var(--fontHeading);
	margin: 0 auto;
	outline: 1px solid #969291;
	padding: 0 15px 15px;
	text-align: left;
	text-transform: none;
	transition: height 0s 150ms linear;
	width: 200px;
	z-index: 9999;

	#signInButton {
		width: 100%;
	}

	.accountQuickLinks {
		margin: 0px auto 0;

		li {
			margin: 0;
			padding: 5px 0;
			text-align: left;

			&:not(:last-child) {
				border-bottom: 1px solid #989898;
			}

			a {
				align-items: center;
				display: flex;
				font: 400 1.4rem/42px var(--fontHeading);

				&:hover {
					color: $lpRed;
				}

				span {
					font-size: 2.4rem;
					margin-right: 20px;
				}
			}
		}
	}
}

#loginForm {
	font-family: var(--fontHeading);
	margin: 0 auto;
	padding: 0 15px 15px;
	text-transform: none;
	width: 330px;

	.formHeader {
		color: #333;
		cursor: text;
		font-size: 20px;
		margin: 10px 0 0;
	}

	.rememberMeContainer {
		margin-top: 10px;
	}

	.forgotPassword {
		margin-left: auto;
	}

	.requiredNote {
		margin-top: 0;
	}

	.accountFormNote {
		font-size: 1.2rem;
		text-transform: capitalize;
	}

	.signInBtn {
		width: 100%;
		margin-top: 20px;
	}

	.signInError,
	.signInMessage {
		color: $lpRed;
		font-size: 1.45rem;
		font-weight: 500;
		line-height: 1.2em;

		&:not(:empty) {
			margin-bottom: 1em;
		}
	}

	.signInError:empty {
		display: none;
	}

	.signInError:not(:empty) {
		.inlineSignInContainer & {
			margin-top: 12px;
		}
	}

	.inputCheckbox {
		margin: 5px 0 1px 0;
	}

	#resendActivationEmail {
		color: inherit;
		font-size: 1.33rem;
		font-weight: inherit;
		line-height: inherit;
		padding: 0;
		text-decoration: underline;
		white-space: nowrap;

		.inlineSignInContainer & {
			font-size: 1.54rem;
		}
	}

	#forgotCreateAccountLinks {
		font-size: 1.2rem;
		margin-top: 7px;
		text-align: center;
		text-transform: capitalize;

		.inlineSignInContainer & {
			margin-top: -14px;
			text-align: right;
		}

		a {
			text-decoration: underline;

			&:hover {
				color: $lpRed;
			}
		}
	}

	#accountFacebookConnectBtn {
		background: #3b5998 url('/img/global/icon-f.png') no-repeat 17px 9px;
		border: 0;
		border-radius: 5px;
		box-sizing: border-box;
		color: #fff;
		display: inline-block;
		height: 44px;
		line-height: 44px;
		margin-bottom: 10px;
		outline-offset: 3px;
		padding-left: 30px;
		text-align: center;
		text-decoration: none;
		width: 100%;

		&:hover,
		&:focus {
			background-color: #4467b1;
		}

		&::-moz-focus-inner {
			border: 0;
			padding: 0;
		}
	}

	#createAccountContainer {
		border-top: 1px solid #999;
		padding-top: 9px;
		margin: 10px 0 14px 0;
	}

	#createAccount {
		height: 44px;
		line-height: 44px;
		margin: 10px 0 0;
		width: 100%;
	}

	#signInSmallOrContainer {
		border-bottom: 1px solid #ccc;
		margin: 25px 0 35px;
		position: relative;

		.or {
			background: #fff;
			font-size: 1.4rem;
			font-weight: normal;
			left: 140px;
			padding: 2px 14px;
			position: absolute;
			text-transform: capitalize;
			text-transform: uppercase;
			top: -12px;
		}
	}
}

#accountSignInSmall {
	font-family: var(--fontHeading);
	margin: 10px auto 0;
	padding: 15px;
	text-transform: none;
	width: 280px;

	.inlineSignInContainer & {
		padding-top: 0;
		width: 365px;
	}

	input[type='email'],
	input[type='text'],
	input[type='password'] {
		border: 1px solid #999;
		box-sizing: border-box;
		font-family: var(--fontBody);
		line-height: normal;
		padding: 4px 2px 4px 10px;
		width: 100%;
	}

	.accountFormNote {
		font-size: 1.2rem;
		text-transform: capitalize;
	}

	.field {
		margin-bottom: 11px;

		.inlineSignInContainer & {
			margin-bottom: 13px;
		}

		> label {
			.inlineSignInContainer & {
				font-size: 1.45rem;
				font-weight: 500;
				line-height: 20px;
			}
		}
	}

	.signInBtnContainer {
		text-align: left;
	}

	.signInBtn {
		font-size: 1.4rem;
		height: 40px;
		margin: 12px 0 0;
		width: 50%;

		.inlineSignInContainer & {
			font-size: 1.54rem;
			height: auto;
			margin-top: 13px;
			width: auto;
		}
	}

	.signInError,
	.signInMessage {
		color: $lpRed;
		font-size: 1.45rem;
		font-weight: 500;
		line-height: 1.2em;

		&:not(:empty) {
			margin-bottom: 10px;
		}
	}

	.signInError:not(:empty) {
		.inlineSignInContainer & {
			margin-top: 12px;
		}
	}

	.inputCheckbox {
		margin: 5px 0 1px 0;
	}

	.or {
		font-size: 1.45rem;
		font-weight: 500;
		margin: 8px 0;
		text-transform: uppercase;
	}

	.accountQuickLinks {
		margin: 15px 0 -10px;

		li {
			border-top: 1px solid #989898;
			height: 42px;
			text-align: center;

			a {
				display: block;
				font: 500 1.4rem/42px var(--fontHeading);

				&:hover {
					color: $lpRed;
				}
			}
		}
	}

	.noAccount {
		margin-top: 22px;
	}

	#resendActivationEmail {
		color: inherit;
		font-size: 1.33rem;
		font-weight: inherit;
		line-height: inherit;
		padding: 0;
		text-decoration: underline;
		white-space: nowrap;

		.inlineSignInContainer & {
			font-size: 1.54rem;
		}
	}

	#forgotCreateAccountLinks {
		font-size: 1.2rem;
		margin-top: 7px;
		text-align: center;
		text-transform: capitalize;

		.inlineSignInContainer & {
			margin-top: -14px;
			text-align: right;
		}

		a {
			text-decoration: underline;

			&:hover {
				color: $lpRed;
			}
		}
	}

	#accountFacebookConnectBtn {
		background: #3b5998 url('/img/global/icon-f.png') no-repeat 17px 9px;
		border: 0;
		border-radius: 5px;
		box-sizing: border-box;
		color: #fff;
		display: inline-block;
		font: normal 1.6rem var(--fontBody);
		height: 44px;
		line-height: 44px;
		margin-bottom: 10px;
		padding-left: 30px;
		text-align: center;
		text-decoration: none;
		width: 100%;

		&:hover,
		&:focus {
			background-color: #4467b1;
		}

		&::-moz-focus-inner {
			border: 0;
			padding: 0;
		}
	}

	#createAccountContainer {
		border-top: 1px solid #999;
		padding-top: 9px;
		margin: 10px 0 14px 0;
	}

	#createAccount {
		height: 44px;
		line-height: 44px;
		margin: 10px 0 0;
		width: 100%;
	}

	#signInSmallOrContainer {
		border-bottom: 1px solid #000;
		margin: 9px 0 20px;
		position: relative;

		.or {
			background: #fff;
			font-size: 1.45rem;
			font-weight: 500;
			left: 160px;
			padding: 2px 14px;
			position: absolute;
			text-transform: none;
			top: -20px;
		}
	}
}