.ie11-wrapper {
	position: absolute;
	top: 40px;
	z-index: 99999;

	&.recentsearches {
		left: 0;
	}

	#hospitalityMaskSearchMode ~ & {
		left: 82px;
	}

	#recentlyViewedSearchWidgetContainer {

		.certonaSearchWidgetHeading {
			display: flex;
			justify-content: space-between;
			padding: 10px 20px;
			.certonaSearchWidgetContainer__header {
				font-size: 1.5rem;
				font-weight: 500;
			}
	
		}
		
		.certonaSearchWidgetContainer__list {
			display: flex;
			padding: 0 10px;
			.certonaSearchWidgetContainer__item {
				margin: 8px;
				img {
					height: 80px;
    				object-fit: contain;
				}
			}

			.recentlyViewedItem.searchdropdown-root__suggestion_active>img{
				outline: var(--fieldHighlightWidth) solid var(--fieldHighlightColor) !important;
				outline-offset: calc(0 * var(--fieldHighlightWidth));
			}
		}

	}
}

.searchContainer.stickyFilter #hospitalityMaskSearchMode ~ div.ie11-wrapper {
	left: calc(50% - 117px);
	transform: translateX(5%);
	top: 78%;
}


.searchSuggestionCount {
	display: inline-block;
	font-size: 1.2rem;
	margin: 0 40px 0 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 250px;
}

.searchdropdown-root {
	background-color: #fff;
	border: 1px solid #ddd;
	box-shadow: 2px 7px 7px 1px rgba(0, 0, 0, 0.4);
	display: flex;
	padding: 0;

	&__delimeter {
		border-left: solid 1px #ddd;
		flex: 1 0 auto;
		margin: 2em 0;
	}

	&__suggestions {
		flex: 1 0 auto;
		min-width: 100px;
		padding: 18px 0;

		.recentsearches & {
			padding: 5px 0;
		}
	}

	&__suggestion {
		cursor: pointer;
		display: inline-block;
		line-height: 28px;
		padding: 0 40px 0 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		text-transform: lowercase;
		white-space: nowrap;
		width: 250px;

		&_infield {
			color: #aaa;
			padding-left: 4em;
		}

		&_active {
			&.certonaSearchWidgetContainer__viewAllBtn, 
			&.searchdropdown-root__clear {
				color: #000;
			}
		}

		&-wrapper{
			align-items: center;
			display: flex;
			justify-content: flex-start;
			padding: 2px 0;

			&.hoverActive:hover, &.keyActive {
				background: #eeeeee;
			}
		}
	}

	&__products {
		align-content: flex-start;
		box-sizing: border-box;
		display: flex;
		flex: 1 1 auto;
		flex-wrap: wrap;
		min-width: 350px;
		padding: 10px 20px;
	}

	&__product {
		box-sizing: border-box;
		flex: 1 1 50%;
		margin-bottom: 25px;
		padding: 0;
		text-align: center;
	}

	&__product-image {
		cursor: pointer;
		height: 120px;
		width: 120px;
	}

	&__product-title {
		cursor: pointer;
		font-size: 12px;
		height: 32px;
		line-height: 16px;
		margin: 0 auto;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 120px;
	}

	&__title {
		font-size: 1.5rem;
		font-weight: 500;
		line-height: 28px;
		margin: 0 60px 7px 20px;
	}

	&__clear {
		color: #888;
		cursor: pointer;
		line-height: 28px;
		margin: 7px 40px 0 20px;
		text-decoration: underline;

		&:hover {
			color: #000;
		}
	}

	&__message {
		flex: 1 0 100%;
		font-size: 1.2em;
		margin: 10px 0 20px;
		width: 100%;
	}

	.loadingIcon {
		margin: auto;
	}
}
