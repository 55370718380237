/* horizontal more you may like */
.certonaContainer {
	clear: both;
	margin: 10px auto 0;
	width: 858px;
}

.certonaContainer .prev,
.certonaContainer .next {
	background: url('/images/hp/hp-sprite.png') no-repeat;
	cursor: pointer;
	display: block;
	float: left;
	height: 27px;
	margin-top: 80px;
	overflow: hidden;
	width: 18px;
}

.certonaContainer .prev {
	background-position: 0 -58px;
	margin-right: 15px;
}

.certonaContainer .prev.disabled {
	background-position: -48px -58px;
	cursor: default;
}

.certonaContainer .next {
	background-position: -72px -58px;
	margin-left: 15px;
}

.certonaContainer .next.disabled {
	background-position: -119px -58px;
	cursor: default;
}

.certonaScrollable {
	float: left;
	height: 280px;
	overflow: hidden;
	position: relative;
	width: 792px;
}

.certonaItems {
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	width: 20000em;
}

.certonaItems li {
	float: left;
}

.certonaItems a {
	display: block;
	float: left;
	margin: 0;
	text-decoration: none;
	width: 180px;
}

.certonaItems a + a {
	margin: 0 0 0 24px;
}

.certonaItems img {
	border: 1px solid var(--grey);
}

.certonaItems p {
	color: #666;
	font-size: 12px;
	height: 43px;
	line-height: 16px;
	margin-top: 10px;
	width: 167px;
}

.certonaItems a:hover p {
	color: var(--darkRed);
}

.certonaItems .scrllPrice {
	color: var(--baseTextColor) !important;
	font-size: 1.45rem;
	font-weight: 500;
	position: relative;
}

.recentlyViewedOuterWrapper {
	background: #fff;
	padding-bottom: 40px;
}

.recentlyViewedWrapper {
	position: relative;
	
	&.loaded {
		border-top: 1px solid var(--grey);
		width: 488px;

		h2 {
			font: 400 2rem var(--fontHeading);
		}
	}
}



.suggestedProductsContainer {
	.moreViewButton {
		padding: 3px 0 0 5px;
	}

	&.loaded {
		.glider-buttons-inline {
			display: flex;
		}
	}
}

#lblStandardFooter{
	.recentlyViewedWrapper.loaded {
		h2 {
			font: 400 2rem var(--fontHeading);
		}
	}	
}
#recentlyViewedContainer {
	margin-bottom: 0;
	margin: 25px -6px 0px -6px;
	padding-bottom: 15px;

	.moreLikeThisLink {
		border: 1px solid #999;
		box-sizing: border-box;
		display: inline-block;
		font-size: 1.4rem;
		font-weight: normal;
		line-height: 10px;
		margin-top: 5px;
		padding: 6px;
		text-decoration: none;
		text-align: center;
		text-transform: uppercase;
		width: 160px;
	}
	
	.sortTrendingProdContainer {
		a {
			padding: 0;
		}
	}
}

.sortMoreYouMayLike,
.suggestedProductsContainer {
	margin-bottom: 26px;
	padding-top: 20px;

	&.installationMoreYouMayLike {
		margin-top: 40px;
	}

	strong.certonaHdr {
		font-size: 1.5rem;
	}

	.sortMoreYouMayLikeTitle,
	.sortTrendingTitle {
		color: #000;
		display: block;
		font-size: 2rem;		
		margin: 0 0 3px;
		text-transform: capitalize;
	}

	#certonaItems,
	#trendingCertonaItems {
		display: flex;
		justify-content: space-between;
		margin-top: 34px;
		padding: 0 20px;
	}

	.sortMYMLProdContainer,
	.sortTrendingProdContainer {
		font-weight: 500;
		margin: 0;
		text-align: center;

		a {
			display: block;
			padding: 0px 2px;
			text-decoration: none;

			> span {
				display: block;
				font-family: var(--fontBody);
				text-align: center;
			}
			img {
				border-radius: 5px;
			}
		}

		img {
			margin-bottom: 4px;
		}
	}


	.sortMYMLProdContainer--landing {
		text-align: left;
	}

	.collapsibleItem {
		&:hover > div {
			color: var(--red);
		}

		> div {
			font-weight: 500;
		}
	}
}

.lpFooter {
	.suggestedProductsContainer {
		#recentlyViewedContainer {
			@media (min-width:768px) {
				.sortMYMLProdContainer {
					& a:last-child {
						font-weight: normal;
						text-align: center;
					}
				}
			}
			.moreYouMayLikeItem {
				.productSummaryRating {
					margin: 5px 0;

					.goldReviewStars {
						justify-content: left;

						&.invisible {
							visibility: hidden;
						}
					}
				}
				a > span {
					margin: 5px 0px;
					text-align: left;

					&.salePrice {
						color: var(--darkRed);
					}
				}
				.productName {
					font-size: 12px;
					font-weight: normal;
					height: 35px;
					overflow: hidden;
				}
			}

			.glider-slide {
				margin: 0px;
				padding: 3px;
				text-align: left;

				a {
					margin: 4px;
					border-radius: 5px;
				}
			}

			.goldReviewStars__star {
				--goldReviewStarsSize: 1.5rem;
			}
		}
	}
}
.confExpiredMYMItems{
	padding: 7px;
}