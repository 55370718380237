@import '../mixins';

:root {
	// TODO: confirm/remove baseFontSize, baseLineHeight
	--baseFontSize: 1.6rem;
	--baseLineHeight: 1.3125;

	--baseBackgroundColor: #fff;
	--baseBorderColor: #ccc;
	--baseBorderColorHighContrast: #000;
	--baseBoxShadow: 4px 4px 12px rgba(0, 0, 0, 0.22);
	--baseTextColor: #333;

	--baseContrastBackgroundColor: #555;
	--baseContrastBorderColor: #555;
	--baseContrastTextColor: #fff;

	// Semanticless Colors
	// Greyscale
	--lightGrey: #f1f1f1;
	--grey: #ccc;
	--darkGrey: #555;
	// Rainbow Order (ROYGBIV)
	--red: #c00;
	--darkRed: #900;
	--highlightRed: #e00;
	--systemRed: #ffebeb;
	--systemYellow: #f9fdbd;
	--green: #090;
	--blue: #06b;
	--strongBlue: #07b;
	--darkBlue: #0e6394;
	--systemBlue: #e8f0fe;

	// Global Font Family
	--fontBody: 'Gotham', Arial, Helvetica, sans-serif;
	--fontHeading: 'Gotham', Arial, Helvetica, sans-serif;
	--fontSerif: 'ChronicleDeckRoman', Baskerville, Palatino, 'Times New Roman', serif;

	--surfaceTransitionDuration: 320ms;
	--surfaceTimingFunction: cubic-bezier(0.55, 0, 0.1, 1);

	--elementAnimationDuration: 250ms;
	--elementTimingFunction: ease-in-out;

	--underlayBackground: rgba(0, 0, 0, 0.6);

	--fieldHighlightWidth: 2px;
	--fieldHighlightColor: var(--blue);
	--fieldPlaceholderTextColor: #999;

	//global button color calloutBtn
	--baseButtonColor: #b60610;
	--baseButtonVisitedColor: #b60610;
	--baseButtonHoverColor: #d00;
	--baseButtonSelectedColor: #d00;
}

@include media(desktop) {
	:root {
		--baseFontSize: 1.4rem;
		--baseLineHeight: 1.5;
	}
}
